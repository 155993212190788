import React from "react";

interface FollowUpCardProps {
  name: string;
  date: string;
  message: string;
  imageSrc: string;
}

const FollowUpCard: React.FC<FollowUpCardProps> = ({
  name,
  date,
  message,
  imageSrc,
}) => {
  return (
    <div className="rounded-lg bg-white p-6 text-gray-800 shadow-md dark:bg-gray-800 dark:text-white">
      <div className="flex items-center">
        <img
          className="mr-4 h-10 w-10 rounded-full"
          src={imageSrc}
          alt={`Avatar of ${name}`}
        />
        <div className="text-sm">
          <p className="leading-none text-gray-900 dark:text-gray-300">
            {name}
          </p>
          <p className="text-gray-600 dark:text-gray-400">{date}</p>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-gray-800 dark:text-gray-200">{message}</p>
      </div>
      <div className="mt-4">
        <button className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
          Follow Up
        </button>
      </div>
    </div>
  );
};

export default FollowUpCard;
