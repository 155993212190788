import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProjectState {
  description?: string;
  ended_at?: { date: string };
  followup_status?: boolean;
  id?: number;
  images?: string;
  pending_fields?: string[];
  progress?: number;
  started_at?: { date: string };
  status?: string;
  title?: string;
  total_followup?: number;
  project_legal_id?: string;
  project_tax_id?: string;
}

const initialState: ProjectState | null = {};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    // Reducer functions for updating the state
    setProject: (state, action: PayloadAction<ProjectState | null>) => {
      if (action.payload == null) return initialState;
      return { ...state, ...action.payload };
    },
  },
});

// Export action creators if needed
export const { setProject } = projectSlice.actions;

export default projectSlice.reducer;
