import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Loader } from "@/components/ui/loader";
import { cn } from "@/lib/utils";
import axiosApi from "@/utils/axiosInterceptor";
import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import useSWR from "swr";
import * as Yup from "yup";
interface ICustomerForm {
  geturl?: string;
  className?: string;
}
const AddCustomerForm: FC<ICustomerForm> = ({ className }) => {
  const { projectId } = useParams();
  const [templateId, setTemplateID] = useState("");
  const [file, setFile] = useState<any>("");

  const handleSubmitData = async (values, setSubmitting) => {
    try {
      setSubmitting(true);
      const response = await axiosApi.post(
        `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/customer/add/`,
        { ...values, project_id: projectId },
      );

      setSubmitting(false);
      toast.success(response.data.message);
    } catch (e) {
      setSubmitting(false);
    }
  };

  const initialData = {
    template_id: "",
    full_name: "",
    mobile_number: "",
    channel: "",
  };

  const customerValidationSchema = Yup.object().shape({
    template_id: Yup.string().required("Template ID is required"),
    full_name: Yup.string().required("Full Name is required"),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, "Must be a valid phone number")
      .required("Mobile Number is required"),
    channel: Yup.string().required("Channel is required"),
  });
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const getTemplateList = async () => {
    try {
      const response = await axiosApi.get(
        `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/project/${projectId}/template/`,
      );
      return response.data.success;
    } catch (e) {
      console.log(e);
    }
  };

  const handleExcelUpload = async () => {
    try {
      const formData = new FormData();
      const project: any = projectId;
      formData.append("project_id", project);
      formData.append("template_id", templateId);
      formData.append("file", file);

      // Log the FormData to check if it's populated correctly

      const response = await axiosApi.post(
        `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/customer/add/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        },
      );
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const { data: templateList } = useSWR(
    "`/api/v1/builder/project/${projectId}/template/",
    getTemplateList,
  );

  const getExcelTemplate = async () => {
    try {
      const response = await axiosApi.get(
        `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/customer/excel-template/`,
        { responseType: "arraybuffer" },
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "customer_excel_template.xlsx";

      // Trigger a click event to simulate the download
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    }
  };
  const handleTemplateChange = (e) => {
    const selectedTemplateID = e.target.value;
    setTemplateID(selectedTemplateID);
  };
  return (
    <>
      <div className={cn("my-8 rounded-xl border p-4 shadow-lg", className)}>
        <p className="mb-3 font-bold underline underline-offset-2 dark:text-white">
          Customer Details
        </p>
        <Formik
          enableReinitialize
          initialValues={initialData}
          validationSchema={customerValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmitData(values, setSubmitting);
          }}
        >
          {({ handleChange, handleBlur, isSubmitting, values }) => (
            <Form className="">
              <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {/* Template ID */}
                <div className="mb-4">
                  <p className="mb-1 block text-sm font-medium text-gray-700">
                    Template ID
                  </p>
                  <select
                    id="template_id"
                    name="template_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.template_id}
                    className=" w-full rounded-md border bg-white p-2"
                  >
                    <option value="" disabled>
                      Select a template
                    </option>
                    {templateList &&
                      templateList.map((template) => (
                        <option key={template.id} value={template.id}>
                          {template.title}
                        </option>
                      ))}
                  </select>
                </div>

                {/* Full Name */}
                <div className="mb-4">
                  <p className="mb-1 block text-sm font-medium text-gray-700">
                    Full Name
                  </p>
                  <Input
                    type="text"
                    id="full_name"
                    name="full_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.full_name}
                    className="mt-1 w-full rounded-md border p-2"
                  />
                </div>

                {/* Mobile Number */}
                <div className="mb-4">
                  <p className="mb-1 block text-sm font-medium text-gray-700">
                    Mobile Number
                  </p>
                  <Input
                    type="text"
                    id="mobile_number"
                    name="mobile_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobile_number}
                    className="mt-1 w-full rounded-md border p-2"
                  />
                </div>

                {/* Channel */}
                <div className="mb-4">
                  <p className="mb-1 block text-sm font-medium text-gray-700">
                    Channel
                  </p>
                  <Input
                    type="text"
                    id="channel"
                    name="channel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.channel}
                    className="mt-1 w-full rounded-md border p-2"
                  />
                </div>
              </div>
              <div className="mt-2 flex justify-end">
                <Button type="submit">
                  {!isSubmitting && "Submit"}
                  {isSubmitting && <Loader size={20} colors="" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="my-10 flex items-center">
          <div className="flex-1 border"></div>
          <p className="m-2">or</p>
          <div className=" flex-1 border"></div>
        </div>
        <div className="flex items-center justify-between ">
          <div className="mb-4">
            <p className="mb-1 block text-sm font-medium text-gray-700">
              Template ID
            </p>
            <select
              id="template_id"
              name="template_id"
              onChange={handleTemplateChange}
              value={templateId}
              className=" w-full rounded-md border bg-white p-2"
            >
              <option value="" disabled>
                Select a template
              </option>
              {templateList &&
                templateList.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.title}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="fileInput"
              className="mb-2 block text-sm font-medium text-gray-700"
            >
              Upload a file (xlsx, xls, csv)
            </label>
            <div
              className={`flex items-center justify-center rounded-md border ${templateId == "" ? "bg-gray-300" : "bg-white"} px-4 py-2 `}
            >
              <input
                type="file"
                id="fileInput"
                accept=".xlsx, .xls, .csv"
                onChange={handleFileChange}
                disabled={templateId == "" ? true : false}
                className="hidden"
              />
              <label htmlFor="fileInput" className="cursor-pointer ">
                Choose File:
              </label>
              {file == "" ? (
                <span className="ml-2" id="fileName">
                  No file chosen
                </span>
              ) : (
                file != "" && <p className=" ml-2 shadow">{file?.name}</p>
              )}
            </div>
          </div>
          <Button
            disabled={file == "" ? true : false}
            onClick={() => handleExcelUpload()}
          >
            Upload Excel
          </Button>
          <Button onClick={() => getExcelTemplate()}>
            Download Excel Template
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddCustomerForm;
