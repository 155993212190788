import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Check, Linkedin, Mail } from "lucide-react";
import { LightBulbIcon, WhatsAppIcon } from "./Icons";
import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";

export const HeroCards = () => {
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent("hi"); // Encode the message
    const url = `https://wa.me/+917223000999?text=${message}`; // WhatsApp URL with pre-filled message
    window.open(url, "_blank"); // Redirect to WhatsApp
  };

  return (
    <div className="relative hidden h-[500px] w-[700px] flex-row flex-wrap gap-8 lg:flex">
      {/* Testimonial */}
      <Card className="absolute -top-[15px] w-[340px] shadow-black/10 drop-shadow-xl dark:shadow-white/10">
        <CardHeader className="flex flex-row items-center gap-4 pb-2">
          <Avatar>
            <AvatarImage alt="" src="https://github.com/shadcn.png" />
            <AvatarFallback>SH</AvatarFallback>
          </Avatar>

          <div className="flex flex-col">
            <CardTitle className="text-lg">Richa</CardTitle>
            <CardDescription>@Bot</CardDescription>
          </div>
        </CardHeader>

        <CardContent>
          <p>AI Bot is new business!</p>
          <Button
            variant={"outline"}
            className="mt-2"
            onClick={handleWhatsAppClick}
          >
            <WhatsAppIcon /> Try Me
          </Button>
        </CardContent>
      </Card>

      {/* Team */}
      <Card className="absolute right-[20px] top-4 flex w-80 flex-col items-center justify-center shadow-black/10 drop-shadow-xl dark:shadow-white/10">
        <CardHeader className="mt-8 flex items-center justify-center pb-2">
          <img
            src="https://i.pravatar.cc/150?img=58"
            alt="user avatar"
            className="absolute -top-12 aspect-square h-24 w-24 rounded-full object-cover grayscale-[0%]"
          />
          <CardTitle className="text-center">Estaban Gonsalves</CardTitle>
          <CardDescription className="font-normal text-primary">
            SRM
          </CardDescription>
        </CardHeader>

        <CardContent className="pb-2 text-center">
          <p>DM me for more information regarding our A.I. products</p>
        </CardContent>

        <CardFooter>
          <Button
            variant={"outline"}
            onClick={() => {
              const message = encodeURIComponent("hi"); // Encode the message
              // const url = `https://wa.me/+917440323456?text=${message}`; // WhatsApp URL with pre-filled message
              // const url= `mailto:sales@support.com`
              // window.open(url, "_blank");
              const url = "mailto:sales@support.com";
              window.open(url, "_blank");
            }}
          >
            {/* <WhatsAppIcon /> */}
            <Mail className="mr-4" />
            <div>sales@support.com</div>
          </Button>
        </CardFooter>
      </Card>

      {/* Pricing */}
      <Card className="absolute left-[50px] top-[200px] w-72  shadow-black/10 drop-shadow-xl dark:shadow-white/10">
        <CardHeader>
          <CardTitle className="item-center flex justify-between">
            Free
            <Badge variant="secondary" className="text-sm text-primary">
              Most popular
            </Badge>
          </CardTitle>
          <div>
            <span className="text-3xl font-bold">$0</span>
            <span className="text-muted-foreground"> /month</span>
          </div>

          <CardDescription>
            Contact us today to learn more about Generative Powered Sales Agent
            and how it can transform your business.
          </CardDescription>
        </CardHeader>

        <CardContent>
          <Button className="w-full">Start Free Trial</Button>
        </CardContent>

        <hr className="m-auto mb-4 w-4/5" />

        <CardFooter className="flex">
          <div className="space-y-4">
            {["1 Project", "Unlimited Template"].map((benefit: string) => (
              <span key={benefit} className="flex">
                <Check className="text-green-500" />{" "}
                <h3 className="ml-2">{benefit}</h3>
              </span>
            ))}
          </div>
        </CardFooter>
      </Card>

      {/* Service */}
      {/* <Card className="absolute -right-[10px] bottom-[35px] w-[350px]  shadow-black/10 drop-shadow-xl dark:shadow-white/10">
        <CardHeader className="flex items-start justify-start gap-4 space-y-1 md:flex-row">
          <div className="mt-1 rounded-2xl bg-primary/20 p-1">
            <LightBulbIcon />
          </div>
          <div>
            <CardTitle>Light & dark mode</CardTitle>
            <CardDescription className="text-md mt-2">
              Lorem ipsum dolor sit amet consect adipisicing elit. Consectetur
              natusm.
            </CardDescription>
          </div>
        </CardHeader>
      </Card> */}
    </div>
  );
};
