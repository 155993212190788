import { FC } from "react";

export interface CardData {
  id: number;
  title: string;
  description: string;
  status: string;
  minimum_message: number;
  started_at: {
    date: string;
    time: string;
  };
  ended_at: {
    date: string;
    time: string;
  };
  created_at: {
    date: string;
    time: string;
  };
  updated_at: {
    date: string;
    time: string;
  };
  progress: number;
  images: { url: string }; // Replace 'any' with the actual type
  pending_fields: Array<string>;
}

const ProjectCard: FC<CardData> = ({
  title,
  description,
  status,
  minimum_message,
  started_at,
  ended_at,
  images,
  progress,
}) => {
  return (
    <div className="h-44 rounded-lg border bg-white p-4 shadow-md dark:border-gray-600 dark:bg-gray-800">
      {
        <div className="h-24">
          <div className="flex  justify-between">
            <div className="mr-4 ">
              <h3 className="mb-5 text-xl font-semibold text-gray-800 dark:text-white">
                {title}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300">
                {description.slice(0, 100) +
                  (description.length > 100 ? "..." : "")}
              </p>
            </div>
            <div>
              {status && (
                <p
                  className={`rounded-lg border p-2 text-xs ${status === "INACTIVE" ? "border-red-400 text-red-400 dark:border-red-400 dark:text-gray-400" : "border-green-400 text-green-400 dark:text-green-400"}`}
                >
                  {status}
                </p>
              )}
            </div>
          </div>
        </div>
      }

      <div className="mt-4  ">
        {/* Display tags if available */}
        {/* {status && (
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Status: {status}
          </p>
        )}

     
        {minimum_message && (
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Minimum Message: {minimum_message}
          </p>
        )}

        {started_at && (
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Project Started at: {started_at.date}
          </p>
        )}

        {ended_at && (
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Ended at: {ended_at.date ? ended_at.date : "0"}
          </p>
        )} */}

        <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
          Completed: {progress}%
        </p>

        <div className="progress mt-2 h-2 w-full rounded-full bg-gray-200 dark:bg-gray-600">
          <div
            className={`progress-bar h-full rounded-full bg-blue-500 dark:bg-blue-400`}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
