import { Button } from "@/components/ui/button";
import { Loader } from "@/components/ui/loader";
import { ScrollArea } from "@/components/ui/scroll-area";
import axios from "axios";
import { useState } from "react";

import useSWR from "swr";

const MayTAPI = () => {
  const [image, setImage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [phoneId, setPhoneId] = useState("");
  const handleQR = async (url) => {
    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          "x-maytapi-key": `${import.meta.env.VITE_MAYTAPI_TOKEN}`,
        },
        responseType: "blob", // Set responseType to blob to receive binary data
      });

      // Create a blob URL for the binary image data
      const blob = new Blob([response.data], { type: "image/png" });
      const dataUrl = URL.createObjectURL(blob);
      console.log("respo", response.data);
      setImage(dataUrl);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  //   const { data: Screen } = useSWR(
  //     `${import.meta.env.VITE_MAYTAPI_SERVER_URL}/${import.meta.env.VITE_MAYTAPI_PRODUCT_ID}/31535/screen`,
  //     handleQR,
  //   );

  const handlePhoneList = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "x-maytapi-key": `${import.meta.env.VITE_MAYTAPI_TOKEN}`,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };
  const { data: listPhones } = useSWR(
    `${import.meta.env.VITE_MAYTAPI_SERVER_URL}/${import.meta.env.VITE_MAYTAPI_PRODUCT_ID}/listPhones`,
    handlePhoneList,
  );
  function getStatusColor(status) {
    switch (status) {
      case "active":
        return "text-green-600";
      case "idle":
        return "text-yellow-600";
      case "qr-screen":
        return "text-blue-600";
      default:
        return "text-gray-600";
    }
  }

  return (
    <>
      <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
        <p className="font-bold capitalize">WhatsApp</p>
      </div>
      <div className="grid grid-cols-3 gap-4 p-4">
        <div className="col-span-1">
          {/* <Button
            onClick={() =>
              handleQR(
                `${import.meta.env.VITE_MAYTAPI_SERVER_URL}/${import.meta.env.VITE_MAYTAPI_PRODUCT_ID}/31535/screen`,
              )
            }
          >
            Get Current Status
          </Button> */}
          <ScrollArea className="h-screen">
            {listPhones &&
              listPhones.map((phone, index) => (
                <div
                  key={index}
                  className="mb-2 cursor-pointer rounded-lg border p-4"
                  onClick={() => {
                    handleQR(
                      `${import.meta.env.VITE_MAYTAPI_SERVER_URL}/${import.meta.env.VITE_MAYTAPI_PRODUCT_ID}/${phone.id}/screen`,
                    ),
                      setPhoneId(phone.id);
                  }}
                >
                  <div className=" text-xl font-semibold">{phone?.name}</div>
                  <div className=" text-gray-600">ID: {phone?.id}</div>
                  <div className=" text-gray-600">Number: {phone?.number}</div>
                  <div
                    className={`text-sm font-semibold ${getStatusColor(phone?.status)} uppercase`}
                  >
                    Status: {phone?.status}
                  </div>
                </div>
              ))}
          </ScrollArea>
        </div>
        <div className="col-span-2">
          {!loading ? (
            <div>
              <div className=" mb-2 flex justify-end">
                <Button
                  variant={"outline"}
                  className="mr-2"
                  onClick={() =>
                    handleQR(
                      `${import.meta.env.VITE_MAYTAPI_SERVER_URL}/${import.meta.env.VITE_MAYTAPI_PRODUCT_ID}/${phoneId}/screen`,
                    )
                  }
                >
                  Refresh
                </Button>
                <Button variant={"outline"}>Redeploy</Button>
              </div>
              {image && <img src={image} alt="QR Code" className=" w-full" />}
            </div>
          ) : (
            <div className=" flex h-screen items-center justify-center">
              <Loader size={100} colors={""} />
            </div>
          )}
          {/* <WhatsAppScreen /> */}
        </div>
      </div>
    </>
  );
};

export default MayTAPI;
