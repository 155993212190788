import { Link } from "react-router-dom";
import OTPModal from "@/components/ui/otpModal";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ForgetPassword: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="flex min-h-screen w-full items-center justify-center bg-white ">
      <div className="flex w-full max-w-sm items-center rounded-2xl border p-5 shadow-lg md:w-auto">
        <div className=" mx-4 flex w-full max-w-sm flex-col justify-center md:mx-auto md:w-80">
          {isModalOpen && (
            <OTPModal isOpen={isModalOpen} onClose={closeModal} />
          )}
          <div className="text-center">
            <h5 className="p-3 text-xl font-bold">Forget Password</h5>
          </div>

          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            country="in"
            autoFormat
            enableSearch
            placeholder="Enter Mobile Number"
            onChange={(e) => console.log("Enter Mobile Number", e)}
            inputStyle={{ width: "100%" }}
            containerStyle={{
              border: "1px solid black",
              borderRadius: "4px",
              marginTop: "5px",
            }}
            buttonStyle={{ borderRight: "1px solid black" }}
          />
          <div className="mt-4 pt-3 text-center">
            <p className="">
              Want to SignIn ?{" "}
              <Link to="/auth/" className="text-blue-500">
                Login
              </Link>{" "}
            </p>
          </div>
          <button onClick={() => openModal()}>otp</button>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
