import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  AppleIcon,
  ArrowLeftRight,
  CreditCard,
  DollarSign,
  Receipt,
  Settings,
} from "lucide-react";

export default function Billing() {
  return (
    <>
      <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
        <p className="font-bold capitalize">Billing</p>
      </div>
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-4">
        <div className="w-full overflow-x-scroll pb-2">
          <Tabs
            defaultValue="overview"
            orientation="vertical"
            className="space-y-4"
          >
            <TabsList className="">
              <TabsTrigger value="overview">Overview</TabsTrigger>
              <TabsTrigger value="paymentMethods">Payment Methods</TabsTrigger>
              <TabsTrigger value="billingHistory">Billing History</TabsTrigger>
              <TabsTrigger value="preferences">Preferences</TabsTrigger>
            </TabsList>
            <TabsContent value="overview">
              <div className="h-svh">
                <div className="mt-5">
                  <h2 className="text-2xl font-bold">Pay as you go</h2>
                  <p className="mt-2 flex items-center gap-2 text-muted-foreground">
                    Credit balance
                  </p>

                  <div>
                    <p className="mt-5 text-2xl font-bold">💲96</p>
                  </div>
                </div>

                <Alert variant="default" title="Note" className="mt-5">
                  {/* // buy token to post jobsnote 50 is free to post jobs */}

                  <AlertTitle>Auto recharge is off</AlertTitle>
                  <AlertDescription>
                    <ul className="list-inside list-disc">
                      <li>
                        When your credits balance reached $0, your API requests
                        will stop working.
                      </li>
                      <li>
                        Enable automatic recharge to avoid service
                        interruptions.
                      </li>
                    </ul>
                  </AlertDescription>
                </Alert>

                <div className="my-5 grid gap-2 md:grid-cols-2 ">
                  <div className="flex flex-row items-center rounded-lg p-4 hover:cursor-pointer">
                    <div className="rounded-lg bg-green-500 p-3">
                      <CreditCard size={30} className="text-white" />
                    </div>
                    <div className="ml-4 mt-2">
                      <h4 className="text-lg font-semibold">
                        Payments Methods
                      </h4>
                      <p className="text-muted-foreground">
                        Add or change payment methods
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center rounded-lg p-4 hover:cursor-pointer">
                    <div className="rounded-lg bg-blue-500 p-3">
                      <Receipt size={30} className="text-white" />
                    </div>
                    <div className="ml-4 mt-2">
                      <h4 className="text-lg font-semibold">Billing History</h4>
                      <p className="text-muted-foreground">
                        View past and current invoices.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center rounded-lg p-4 hover:cursor-pointer">
                    <div className="rounded-lg bg-pink-500 p-3">
                      <Settings size={30} className="text-white" />
                    </div>
                    <div className="ml-4 mt-2">
                      <h4 className="text-lg font-semibold">Preferences</h4>
                      <p className="text-muted-foreground">
                        manage billing information's.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center rounded-lg p-4 hover:cursor-pointer">
                    <div className="rounded-lg bg-red-500 p-3">
                      <ArrowLeftRight size={30} className="text-white" />
                    </div>
                    <div className="ml-4 mt-2">
                      <h4 className="text-lg font-semibold">Usage Limits</h4>
                      <p className="text-muted-foreground">
                        Spend monthly spend limits
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center rounded-lg p-4 hover:cursor-pointer">
                    <div className="rounded-lg bg-yellow-500 p-3">
                      <DollarSign size={30} className="text-white" />
                    </div>
                    <div className="ml-4 mt-2">
                      <h4 className="text-lg font-semibold">Pricing</h4>
                      <p className="text-muted-foreground">
                        View pricing and FAQs
                      </p>
                    </div>
                  </div>
                </div>
                <Alert variant="warning" title="Note">
                  {/* // buy token to post jobsnote 50 is free to post jobs */}

                  <AlertTitle>Note</AlertTitle>
                  <AlertDescription>
                    <ul className="list-inside list-disc">
                      <li>
                        You do not have permission to manage billing
                        information.
                      </li>
                      <li>
                        Only members of your organization with the "Owner" role
                        can manage billing information.
                      </li>

                      <a
                        href="/dashboard/organization-settings"
                        className="ml-5 text-red-500 hover:underline"
                      >
                        View Organization members
                      </a>
                    </ul>
                  </AlertDescription>
                </Alert>
              </div>
            </TabsContent>
            <TabsContent value="paymentMethods">
              <div className="h-svh">
                <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-2">
                  <AppleIcon size={64} />
                  <h1 className="text-4xl font-bold leading-tight">
                    Coming Soon 👀
                  </h1>
                  <p className="text-center text-muted-foreground">
                    This page has not been created yet. <br />
                    Stay tuned though!
                  </p>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="billingHistory">
              <div className="h-svh">
                <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-2">
                  <AppleIcon size={64} />
                  <h1 className="text-4xl font-bold leading-tight">
                    Coming Soon 👀
                  </h1>
                  <p className="text-center text-muted-foreground">
                    This page has not been created yet. <br />
                    Stay tuned though!
                  </p>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="preferences">
              <div className="h-svh">
                <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-2">
                  <AppleIcon size={64} />
                  <h1 className="text-4xl font-bold leading-tight">
                    Coming Soon 👀
                  </h1>
                  <p className="text-center text-muted-foreground">
                    This page has not been created yet. <br />
                    Stay tuned though!
                  </p>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
}
