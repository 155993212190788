import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import image from "@/assets/growth.png";
import image3 from "@/assets/reflecting.png";
import image4 from "@/assets/looking-ahead.png";

interface FeatureProps {
  title: string;
  description: React.ReactNode;
  image: string;
}

const features: FeatureProps[] = [
  {
    title: "Maximize Profits with AI Sales",
    description: (
      <>
        <ul role="list" className="list-disc">
          <li>
            <span className="font-bold">Increase in Revenue:</span> Our
            AI-powered sales processes drive higher conversion rates, leading to
            an average revenue increase of 25% for businesses.
          </li>
          <li>
            <span className="font-bold">Cost Reduction:</span> By automating
            manual sales efforts, companies can save up to 30% in labour costs
            and overhead expenses annually.
          </li>
          <li>
            <span className="font-bold">Profitability Optimization:</span>{" "}
            Businesses leveraging our solution report a 20% improvement in
            profitability through optimized pricing strategies and upselling
            opportunities.
          </li>
        </ul>
      </>
    ),
    image: image4,
  },
  {
    title: "Accelerate Deals, Save Time",
    description: (
      <>
        <ul role="list" className="list-disc">
          <li>
            <span className="font-bold">Faster Deal Closures:</span> With
            streamlined sales processes, our clients experience 40% faster deal
            closures and shorter sales cycles on average.
          </li>
          <li>
            <span className="font-bold">Task Automation:</span> Sales
            representatives save up to 15 hours per week with automated tasks
            and responses, enabling them to focus on high-priority activities.
          </li>
          <li>
            <span className="font-bold">Quick Access to Information:</span> Our
            platform reduces research and decision-making time by 50%, providing
            instant access to relevant information for both customers and sales
            teams.
          </li>
        </ul>
      </>
    ),
    image: image3,
  },
  {
    title: "Empower Teams, Expand Reach",
    description: (
      <>
        <ul role="list" className="list-disc">
          <li>
            <span className="font-bold">Workload Alleviation:</span> Our
            solution enables sales teams to handle 30% larger volumes of leads
            and customers without increasing headcount.
          </li>
          <li>
            <span className="font-bold">Task Efficiency: </span> By reducing
            repetitive tasks, we free up human resources for more strategic and
            creative endeavours, boosting overall productivity by 35%.
          </li>
          <li>
            <span className="font-bold">Job Satisfaction: </span> With more time
            for meaningful interactions, sales representatives experience a 25%
            increase in job satisfaction, leading to higher retention rates.
          </li>
        </ul>
      </>
    ),
    image: image,
  },
  {
    title: "Business Operations Management",
    description: (
      <>
        <ul role="list" className="list-disc">
          <li>
            <span className="font-bold">Enhanced Efficiency:</span> Companies
            using Generative Powered Sales Agent witness a 50% improvement in
            efficiency and productivity across the sales pipeline, resulting in
            increased throughput.
          </li>
          <li>
            <span className="font-bold">Improved Alignment:</span> Better
            alignment between sales, marketing, and customer service departments
            leads to a 30% increase in collaboration and synergy.
          </li>
          <li>
            <span className="font-bold">Market Adaptability:</span> Real-time
            data analysis and insights enable businesses to respond to market
            changes and customer demands 3 times faster, ensuring continued
            success in dynamic environments.
          </li>
        </ul>
      </>
    ),
    image: image3,
  },
  {
    title: "Other Benefits",
    description: (
      <>
        <ul role="list" className="list-disc">
          <li>
            <span className="font-bold">Customer Satisfaction:</span>
            Personalized interactions and tailored solutions result in a 40%
            increase in customer satisfaction and loyalty.
          </li>
          <li>
            <span className="font-bold">Competitive Advantage:</span>Businesses
            utilizing AI-driven sales capabilities stay ahead of competitors,
            experiencing a 2x increase in market share growth.
          </li>
          <li>
            <span className="font-bold">Enhanced Scalability: </span> Our
            platform enables businesses to expand their sales efforts without
            proportional increases in resources or costs, achieving scalable
            growth at 3 times the industry average.
          </li>
        </ul>
      </>
    ),
    image: image4,
  },
];

const featureList: string[] = [
  "Dark/Light theme",
  "Reviews",
  "Features",
  "Pricing",
  "Contact form",
  "Our team",
  "Responsive design",
  "Newsletter",
  "Minimalist",
];

export const Features = () => {
  return (
    <section id="features" className="container space-y-8 py-24 sm:py-32">
      <h2 className="text-3xl font-bold md:text-center lg:text-4xl">
        Transform Your Sales{" "}
        <span className="bg-gradient-to-b from-primary/60 to-primary bg-clip-text text-transparent">
          Strategy with AI-Powered Solutions
        </span>
      </h2>

      {/* <div className="flex flex-wrap gap-4 md:justify-center">
        {featureList.map((feature: string) => (
          <div key={feature}>
            <Badge variant="secondary" className="text-sm">
              {feature}
            </Badge>
          </div>
        ))}
      </div> */}

      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {features.map(({ title, description, image }: FeatureProps) => (
          <Card key={title}>
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>

            <CardContent>{description}</CardContent>

            <CardFooter>
              <img
                src={image}
                alt="About feature"
                className="mx-auto w-[200px] lg:w-[300px]"
              />
            </CardFooter>
          </Card>
        ))}
      </div>
    </section>
  );
};
