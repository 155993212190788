import { useNavigate } from "react-router-dom";
import { HeroCards } from "./HeroCards";

import { Button } from "@/components/ui/button";

export const Hero = () => {
  const navigate = useNavigate();
  return (
    <section className="container grid place-items-center gap-10   py-20 md:py-32 lg:grid-cols-2">
      <div className="space-y-6 text-center lg:text-start">
        <main className="text-5xl font-bold md:text-6xl">
          {/* <h1 className="inline"> */}
          <span className="inline bg-gradient-to-r from-[#4316e7]  to-[#15cea6] bg-clip-text text-transparent">
            Nexby
          </span>{" "}
          {/* landing page
          </h1>{" "}
          for{" "}
          <h2 className="inline">
            <span className="inline bg-gradient-to-r from-[#61DAFB] via-[#1fc0f1] to-[#03a3d7] bg-clip-text text-transparent">
              React
            </span>{" "}
            developers
          </h2> */}
        </main>

        <p className="mx-auto text-xl text-muted-foreground md:w-10/12 lg:mx-0">
          Maximize Sales Efficiency with Generative Powered Sales Agent!
        </p>
        <p className="text-muted-foreground">
          At Nexby Sales Agent, we believe in revolutionizing your sales process
          with the power of artificial intelligence. Our advanced software
          empowers your sales team to excel by providing them with an
          intelligent assistant that's always at their fingertips, ready to
          engage customers and drive sales.
        </p>

        <div className="space-y-4 md:space-x-4 md:space-y-0">
          <Button onClick={() => navigate("auth/")} className="w-full md:w-1/3">
            Get Started
          </Button>
        </div>
      </div>

      {/* Hero cards sections */}
      <div className="z-10">
        <HeroCards />
      </div>

      {/* Shadow effect */}
      <div className="shadow"></div>
    </section>
  );
};
