import React from "react";
import { Link } from "react-router-dom";

const Page404: React.FC = () => {
  return (
    <div className="flex min-h-screen  w-screen items-center justify-center bg-gray-100">
      <div className="p-4 text-center md:p-8 lg:p-16">
        <h1 className="mb-4 text-4xl font-bold text-gray-800 md:text-5xl lg:text-6xl">
          404 - Not Found
        </h1>
        <p className="mb-8 text-lg text-gray-600 md:text-xl lg:text-2xl">
          The page you're looking for doesn't exist.
        </p>
        {/* <Link
          to={token ? "/pages/" : "/auth/"}
          className="text-blue-500 hover:underline"
        >
          Go back to Home
        </Link> */}
      </div>
    </div>
  );
};

export default Page404;
