import React from "react";

const ErrorPage: React.FC = () => {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-red-600">Oops!</h1>
        <p className="mt-4 text-lg text-gray-800">
          Sorry, an unexpected error has occurred.
        </p>
        <p className="mt-2 text-sm text-gray-600">
          {/* <i>{error?.statusText || error?.message}</i> */}
        </p>
        <button
          className="mt-4 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          onClick={reloadPage}
        >
          Reload Page
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
