// ChatModal.tsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { WhatsappEndPoints } from "@/api/endpoints";
import axiosApi from "@/utils/axiosInterceptor";

interface ChatModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

export interface IMessage {
  role: string;
  content: string;
}

const ChatModal: React.FC<ChatModalProps> = ({ isOpen, onClose, userId }) => {
  const [chatdata, setChatdata] = useState([]);
  useEffect(() => {
    handleChatData();
  }, [userId]);

  const handleChatData = async () => {
    try {
      const response = await axiosApi.post(
        `${import.meta.env.VITE_SERVER_URL}${
          WhatsappEndPoints.USERCONVERSATION
        }`,
        {
          user_id: userId,
        },
      );
      setChatdata(response.data.success.conversation);
    } catch (e) {
      //   handleApiError(e);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="h-96 min-w-fit sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-center">User Chats</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <div className="flex h-72 flex-col ">
            <div
              className="flex-1  overflow-y-auto p-2"
              style={{ scrollbarWidth: "none" }}
            >
              {chatdata.length > 0 ? (
                chatdata.map((message: IMessage, index) => (
                  <div
                    key={index}
                    className={`mb-2 ${
                      message.role === "assistant" ? "text-left" : "text-right"
                    }`}
                  >
                    <span
                      className={`mt-2 inline-block transform p-2 ${
                        message.role === "user"
                          ? "rounded-t-lg rounded-bl-lg  bg-blue-500 text-white"
                          : "rounded-t-lg  rounded-br-lg bg-gray-300"
                      }`}
                    >
                      {message.content}
                    </span>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500">
                  "No chats available at the moment"
                </div>
              )}
            </div>
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default ChatModal;
