import * as React from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface DatePickerWithRangeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  selectedDate: (data: any | undefined | object) => void;
  disabledDays?: { after: any; before: any };
}

export function DatePickerWithRange({
  className,
  selectedDate,
  disabledDays,
}: DatePickerWithRangeProps) {
  const [date, setDate] = React.useState<any | undefined>({
    from: new Date(2023, 11, 1),
    to: addDays(new Date(), 0),
  });

  const handleDateSelect = (selected: any) => {
    setDate(selected);
    selectedDate({
      start_date: format(selected.from, "dd/MM/yyyy"),
      end_date: format(selected.to, "dd/MM/yyyy"),
    });
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal sm:w-full md:w-full lg:w-[250px]",
              !date && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "dd/MM/yyyy")} -{" "}
                  {format(date.to, "dd/MM/yyyy")}
                </>
              ) : (
                format(date.from, "dd/MM/yyyy")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            disabled={(day) =>
              day > disabledDays?.after || day < disabledDays?.before
            }
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleDateSelect}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
