"use client";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface TestimonialProps {
  image: string;
  name: string;
  userName: string;
  comment: string;
}

const testimonials: TestimonialProps[] = [
  {
    image: "https://github.com/shadcn.png",
    name: "Anuj Todankar",
    userName: "@anuj_todankar",
    comment: "AI Bot is awesome business!",
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Yuvraj Yadav",
    userName: "@yuvraj_yadav",
    comment:
      "AI Bot is a great platform for businesses looking to automate their customer service and improve efficiency.",
  },

  {
    image: "https://github.com/shadcn.png",
    name: "Deepak Dabekar",
    userName: "@deepak_dabekar",
    comment:
      "AI Bot has revolutionized the way we interact with customers. It's efficient, accurate, and user-friendly.",
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Nayan Stark",
    userName: "@nayan_stark",
    comment:
      "AI Bot has helped us streamline our customer service operations and provide a seamless experience to our clients.",
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Rahul Thakur",
    userName: "@rahul_thakur",
    comment:
      "AI Bot is a game-changer for businesses looking to enhance their customer service and drive growth.",
  },
  {
    image: "https://github.com/shadcn.png",
    name: "Rahul Rajbhar",
    userName: "@rahul_rajbhar",
    comment:
      "AI Bot is a powerful tool that has transformed the way we engage with customers and deliver exceptional service.",
  },
];

export const Testimonials = () => {
  return (
    <section id="testimonials" className="container py-24 sm:py-32">
      <h2 className="text-3xl font-bold md:text-4xl">
        Discover Why
        <span className="bg-gradient-to-b from-primary/60 to-primary bg-clip-text text-transparent">
          {" "}
          People Love{" "}
        </span>
        Our Product
      </h2>

      <p className="pb-8 pt-4 text-xl text-muted-foreground">
        Don&apos;t just take our word for it. Here&apos;s what our customers
        have to say about their experience with Generative AI HR Recruiter.
      </p>

      <div className="mx-auto grid columns-2 space-y-4 sm:block  md:grid-cols-2 lg:columns-3 lg:grid-cols-4 lg:gap-6 lg:space-y-6">
        {testimonials.map(
          ({ image, name, userName, comment }: TestimonialProps) => (
            <Card
              key={userName}
              className="max-w-md overflow-hidden md:break-inside-avoid"
            >
              <CardHeader className="flex flex-row items-center gap-4 pb-2">
                <Avatar>
                  <AvatarImage alt="" src={image} />
                  <AvatarFallback>OM</AvatarFallback>
                </Avatar>

                <div className="flex flex-col">
                  <CardTitle className="text-lg">{name}</CardTitle>
                  <CardDescription>{userName}</CardDescription>
                </div>
              </CardHeader>

              <CardContent>{comment}</CardContent>
            </Card>
          ),
        )}
      </div>
    </section>
  );
};
