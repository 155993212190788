import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "@/redux/store/store";
import ScreenRoutes from "./screens/navigate";
import AuthRoutes from "./auth";
import LandingPage from "./landing/landing";
import ErrorPage from "./error-page";

const Pages = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.refreshToken !== null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      {isAuthenticated ? (
        <Route path="/pages/*" element={<ScreenRoutes />} />
      ) : (
        <Route path="/auth/*" element={<AuthRoutes />} />
      )}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Pages;
