import { Button } from "@/components/ui/button";
import FollowUpCard from "./follow-up-card";
import { useState } from "react";
import FollowUpModal from "./follow-up-modal";

const FollowUp = () => {
  const [visible, setVisible] = useState(false);
  const handleSubmit = (values) => {
    console.log("value in submit", values);
  };

  return (
    <>
      <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
        <p className="font-bold capitalize">Follow up</p>
      </div>
      <div className="p-4">
        <FollowUpCard
          name="John Doe"
          date="February 16, 2024"
          message="Hey, just wanted to check in and see how things are going."
          imageSrc="https://via.placeholder.com/150"
        />
      </div>
    </>
  );
};

export default FollowUp;
