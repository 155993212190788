import { Dialog, DialogContent } from "@/components/ui/dialog";
import ProjectForm from "./project-form";
import { mutate } from "swr";
import { ProjectEndPoints } from "@/api/endpoints";

const ProjectModal = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-7xl">
        <ProjectForm
          className="border-none shadow-none"
          callback={() => {
            mutate(
              `${import.meta.env.VITE_SERVER_URL}${ProjectEndPoints.GET_PROJECT}`,
            ),
              onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ProjectModal;
