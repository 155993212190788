import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CTable from "@/components/ctable/data-table";
import axiosApi from "@/utils/axiosInterceptor";
import { WhatsappEndPoints } from "@/api/endpoints";
import ChatModal from "./chatmodal";
import LeadModal from "./leadmodal";
import SummaryModal from "./summarymodal";
import { PageDetails } from "@/types/propsType";
import { DataTableRowActions } from "@/components/ctable/data-table-row-actions";
import CFilterModal from "@/components/cfilter-modal/cfilter";

interface IfilterData {
  start_date: string;
  end_date: string;
  status: [string];
}

const WhatsAppBot = memo(() => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageDetails, setPageDetails] = useState<PageDetails>({
    next_page: 0,
    previous_page: 0,
    total_pages: 1,
    items: 1,
    total_entries: 1,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [visiblePages, setVisiblePages] = useState(false);
  const [visibleLeads, setVisibleLeads] = useState(false);
  const [visibleSummary, setVisibileSummary] = useState(false);
  const [userId, setUserId] = useState("");
  const [filterData, setFilterData] = useState<IfilterData>({
    start_date: "",
    end_date: "",
    status: [""],
  });
  const [filterModal, setFilterModal] = useState(false);

  const disabledDays = {
    after: new Date(),
    before: new Date(2023, 11, 1),
  };

  const columns = [
    {
      header: "Sr.no",
      cell: ({ row }) => {
        return <div>{row.index + 1 + (page - 1) * 10}</div>;
      },
    },
    {
      header: "Name",
      accessorKey: "user_name",
    },

    {
      header: "Mobile Number",
      accessorKey: "mobile_number",
    },

    {
      header: "Registered date",
      accessorKey: "user_register_date",
    },
    {
      header: "Time",
      accessorKey: "user_register_time",
    },

    {
      id: "select",
      header: "Action",
      cell: ({ row }) => (
        <div className="">
          <DataTableRowActions
            menuData={[
              {
                buttonName: "Chat",
                buttonFunc: () => {
                  setVisiblePages(true);
                  setUserId(row.original.id);
                },
              },
              {
                buttonName: "Lead",
                buttonFunc: () => {
                  setVisibleLeads(true);
                  setUserId(row.original.id);
                },
              },
              {
                buttonName: "Summary",
                buttonFunc: () => {
                  setVisibileSummary(true);
                  setUserId(row.original.id);
                },
              },
              {
                buttonName: "User details",
                buttonFunc: () => {
                  navigate("/pages/user-details", {
                    state: { userId: row.original.id },
                  });
                },
              },
            ]}
          />
        </div>
      ),
    },
    {
      id: "status",

      header: () => <div className="text-center">Status</div>,
      cell: ({ row }) => (
        <>
          <div
            className={`mx-2 rounded-sm ${
              row.original.status === "OPEN"
                ? "bg-green-600"
                : row.original.status === "CLOSE"
                  ? "bg-red-600"
                  : "bg-gray-900" // Default color for other statuses
            } py-1 text-center font-bold text-white`}
          >
            {row.original.status}
          </div>
        </>
      ),
    },
  ];
  useEffect(() => {
    handleUserdetails();
  }, [page, pageSize, filterData]);

  const handleUserdetails = async () => {
    try {
      const response = await axiosApi.get(
        `${import.meta.env.VITE_SERVER_URL}${
          WhatsappEndPoints.USERDETAILS
        }?page=${page}&items=${pageSize}&start_date=${
          filterData.start_date
        }&end_date=${filterData.end_date}`,
      );
      setData(response.data.success.user_lead);
      setPageDetails(response.data.success.page_details);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelectedDate = async (value) => {
    console.log("handleSelectedDate", value);
  };

  return (
    <div className=" items-center justify-center">
      <div className="p-2">
        <Link
          to="/pages/"
          className="  rounded-sm px-2  text-blue-400 dark:bg-blue-700 dark:text-white"
        >
          back
        </Link>
      </div>

      {visiblePages && (
        <ChatModal
          isOpen={visiblePages}
          onClose={() => setVisiblePages(false)}
          userId={userId}
        />
      )}
      {filterModal && (
        <CFilterModal
          isOpen={filterModal}
          onClose={() => setFilterModal(false)}
        />
      )}
      {visibleLeads && (
        <LeadModal
          isOpen={visibleLeads}
          onClose={() => setVisibleLeads(false)}
          userId={userId}
        />
      )}
      {visibleSummary && (
        <SummaryModal
          isOpen={visibleSummary}
          onClose={() => setVisibileSummary(false)}
          userId={userId}
        />
      )}

      <div className="  h-full flex-1 flex-col space-y-8 px-4  pb-4  md:flex">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <h2 className="text-xl font-bold tracking-tight">
              WhatsApp Dashboard
            </h2>
            <p className="text-muted-foreground">
              Here&apos;s a list of your users !
            </p>
          </div>
        </div>
        {data && (
          <CTable
            data={data}
            columns={columns}
            pageDetails={pageDetails}
            handlePageSize={(pageSize) => {
              setPageSize(pageSize);
            }}
            handlePaginationClick={(pageNo) => {
              setPage(pageNo);
            }}
            searchPlaceholder="Search name or mobile ... "
            handleExport={() => setFilterModal(true)}
            // globalFilter={(e) => console.log(e)}
            dateFn={(dateVal: any) =>
              setFilterData({
                start_date: dateVal.start_date,
                end_date: dateVal.end_date,
                status: [""],
              })
            }
            disabledDate={disabledDays}
          />
        )}
      </div>
    </div>
  );
});

export default WhatsAppBot;
