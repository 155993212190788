import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { IMessage } from "../whatsappbot/chatmodal";
import useSWR from "swr";
import axiosApi from "@/utils/axiosInterceptor";
import { Skeleton } from "@/components/ui/skeleton";
import { WhatsAppBg } from "@/assets";

interface ChatsData {
  user_message_content: string;
  user_message_date: string;
  user_message_time: string;
  bot_response: string;
  bot_response_date: string;
  bot_response_time: string;
}

const CustomerChatModal = ({ isOpen, onClose, geturl, customerName }) => {
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChatData = async (url) => {
    try {
      setIsLoading(true);
      const response = await axiosApi.get(url);
      setChats(response.data.success);
    } catch (error) {
      console.error("Error fetching chat data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useSWR(isOpen ? geturl : null, fetchChatData);

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-7xl">
        <DialogHeader>
          <DialogTitle className="text-center">
            {customerName} Chats
          </DialogTitle>
        </DialogHeader>

        <div className="relative h-80">
          <div
            className="absolute bottom-0 left-0 right-0 top-0 overflow-y-auto rounded-lg"
            style={{ backgroundImage: `url(${WhatsAppBg})`, padding: "20px" }}
          >
            {isLoading ? (
              <div className="text-center text-gray-500">Loading...</div>
            ) : chats?.length > 0 ? (
              chats?.map((message: ChatsData, index) => (
                <div key={index} className="mb-5">
                  <div className="flex flex-col ">
                    {message?.user_message_content ? (
                      <div
                        className={`speech-bubble-right relative my-1 mb-5 ml-auto flex flex-col whitespace-pre-line rounded-lg rounded-t-xl rounded-bl-xl rounded-tr-none border bg-green-300 p-3 text-sm dark:text-black`}
                      >
                        {message?.user_message_content}
                      </div>
                    ) : null}
                    {message?.bot_response ? (
                      <div
                        className={`speech-bubble-left relative my-1 mr-auto flex flex-col whitespace-pre-line rounded-lg  rounded-t-xl rounded-br-xl rounded-tl-none  border  bg-white p-3 text-sm dark:text-black`}
                      >
                        {message?.bot_response}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500">
                No chats available at the moment
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerChatModal;
