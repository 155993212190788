import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import axiosApi from '@/utils/axiosInterceptor';
import useSWR from 'swr';
import { ProjectEndPoints } from '@/api/endpoints';
import { toast } from 'sonner';


const Reports = () => {
  const formik = useFormik({
    initialValues: {
      start_date: '',
      end_date: '',
      project: '',
      status: '',
    },
    validationSchema: Yup.object({
      start_date: Yup.date().required('Start date is required'),
      end_date: Yup.date().required('End date is required'),
      project: Yup.string().required('Project is required'),
      status: Yup.string().required('Status is required'),
    }),
    onSubmit: () => {
      handleDownloadReport()
    },
  });
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const handleDownloadReport = async () => {
    try {
      const response = await axiosApi.post(`${import.meta.env.VITE_SERVER_URL}/api/v1/builder/project/${formik.values.project}/download-reports/`,
        {
          project_id: formik.values.project,
          status: formik.values.status == "all" ? "" : formik.values.status,
          start_date: formatDate(formik.values.start_date),
          end_date: formatDate(formik.values.end_date)

        }
        , { responseType: 'blob' }
      )

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create an anchor element and set the href to the blob URL
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx'); // Set the desired file name and extension

      // Append the link to the document body and click it to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link and revoking the blob URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      toast.error("Something Went Wrong")
    }
  }

  const handleGetProject = async (url) => {
    try {
      const response = await axiosApi.get(url);
      return response.data;
    } catch (e) {
      console.error("Error fetching projects:", e);
      throw e; // Rethrow the error to let SWR handle it
    }
  };
  const { data, error, isLoading } = useSWR(
    `${import.meta.env.VITE_SERVER_URL}${ProjectEndPoints.GET_PROJECT}`,
    handleGetProject,
  );

  const getProjectTitle = (id, projects) => {
    const project = projects.find((proj) => proj.id == id);
    return project ? project.title : 'Select project';
  };

  return (
    <>
      <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
        <p className="font-bold capitalize">Reports</p>
      </div>
      <div className="p-4">
        <div className="">
          <form className="" onSubmit={formik.handleSubmit}>
            <div className='grid grid-cols-1 gap-x-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'>
              {/* catogory */}
              <div className="">
                <Label htmlFor="project">Project</Label>
                <Select
                  name="project"
                  value={formik.values.project}
                  onValueChange={(value) => {
                    formik.setFieldValue("project", value)
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select project">
                      {getProjectTitle(formik.values.project, data?.success || [])}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All</SelectItem>

                    {data?.success?.map((project) => (
                      <SelectItem key={project.id} value={project.id}>{project.title}</SelectItem>))
                    }
                  </SelectContent>
                </Select>
                {formik.touched.project && formik.errors.project ? (
                  <div className="text-red-500 text-sm">{formik.errors.project}</div>
                ) : null}
              </div>
              {/* Date Range */}
              <div className="">
                <Label htmlFor="date-range-start">Date Range</Label>
                <div className="flex gap-2">
                  <Input
                    id="date-range-start"
                    name="start_date"
                    placeholder="Start date"
                    type="date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={formik.touched.start_date && formik.errors.start_date ? 'border-red-500' : ''}
                  />
                  {formik.touched.start_date && formik.errors.start_date ? (
                    <div className="text-red-500 text-sm">{formik.errors.start_date}</div>
                  ) : null}
                  <span className="flex items-center text-gray-500 dark:text-gray-400">-</span>
                  <Input
                    id="date-range-end"
                    name="end_date"
                    placeholder="End date"
                    type="date"
                    min={formik.values.start_date}
                    value={formik.values.end_date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={formik.touched.end_date && formik.errors.end_date ? 'border-red-500' : ''}
                  />
                  {formik.touched.end_date && formik.errors.end_date ? (
                    <div className="text-red-500 text-sm">{formik.errors.end_date}</div>
                  ) : null}
                </div>
              </div>



              {/* Status */}
              <div className="">
                <Label htmlFor="status">Status</Label>
                <Select
                  name="status"
                  value={formik.values.status}
                  onValueChange={(value) => formik.setFieldValue("status", value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    <SelectItem value="open">Open</SelectItem>
                    <SelectItem value="close">Closed</SelectItem>
                    <SelectItem value="pending">Pending</SelectItem>
                  </SelectContent>
                </Select>
                {formik.touched.status && formik.errors.status ? (
                  <div className="text-red-500 text-sm">{formik.errors.status}</div>
                ) : null}
              </div>

              {/* format */}
              {/* <div className="">
                <Label htmlFor="format">Format</Label>
                <Select
                  name="format"
                  value={formik.values.format}
                  onValueChange={(value) => formik.setFieldValue("format", value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select format" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="csv">CSV</SelectItem>
                    <SelectItem value="excel">Excel</SelectItem>
                  </SelectContent>
                </Select>
                {formik.touched.format && formik.errors.format ? (
                  <div className="text-red-500 text-sm">{formik.errors.format}</div>
                ) : null}
              </div> */}
            </div>
            <Button className="mt-8" type="submit">
              Download
            </Button>
          </form>
        </div>

      </div>
    </>
  );
};

export default Reports;
