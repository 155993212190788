// OTPModal.tsx
import React, { ChangeEvent, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface OTPModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const OTPModal: React.FC<OTPModalProps> = ({ isOpen, onClose }) => {
  const numOtpBoxes = 6;

  const [otpValues, setOtpValues] = useState<string[]>(
    Array(numOtpBoxes).fill(""),
  );

  const handleInputChange = (index: number, value: string) => {
    // Validate that only numbers are entered
    if (!/^\d*$/.test(value)) {
      return;
    }

    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;

    // Move to the next input box on input
    if (value !== "" && index < numOtpBoxes - 1) {
      const nextInput = document.getElementById(
        `otp${index + 2}`,
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }

    // Move to the previous input box on clearing
    if (value === "" && index > 0) {
      const prevInput = document.getElementById(
        `otp${index}`,
      ) as HTMLInputElement;
      if (prevInput) {
        prevInput.focus();
      }
    }

    setOtpValues(newOtpValues);
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className=" sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>OTP Verification</DialogTitle>
          <DialogDescription>
            We've sent an OTP to the WhatsApp number you provided, kindly verify
            and enter the code.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-6 items-center gap-4 sm:grid-cols-6 md:grid-cols-6">
            {otpValues.map((value, index) => (
              <input
                key={index}
                type="number"
                id={`otp${index + 1}`}
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(index, e.target.value)
                }
                className="flex h-full w-full appearance-none flex-col items-center justify-center rounded-lg border border-gray-200 bg-white p-2 text-center text-lg outline-none ring-blue-700 focus:bg-gray-50 focus:ring-1"
                min="0"
                max="9"
                maxLength={1}
              />
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OTPModal;
