// SummaryModal.tsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { WhatsappEndPoints } from "@/api/endpoints";
import axiosApi from "@/utils/axiosInterceptor";

interface SummaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}
interface ISummary {
  summary: string;
  summary_created_date: string;
  summary_created_time: string;
}

const SummaryModal: React.FC<SummaryModalProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const [summaryData, setSummaryData] = useState<ISummary>();
  useEffect(() => {
    handleChatData();
  }, [userId]);

  const handleChatData = async () => {
    try {
      const response = await axiosApi.post(
        `${import.meta.env.VITE_SERVER_URL}${WhatsappEndPoints.USERSUMMARY}`,
        {
          user_id: userId,
        },
      );

      setSummaryData(response.data.success);
    } catch (e) {
      //   handleApiError(e);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className=" sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Summarry</DialogTitle>
        </DialogHeader>
        {summaryData?.summary_created_date ? (
          <div>
            <div className="mt-4">
              <DialogDescription className="rounded-md border bg-blue-500 p-2 text-left text-white">
                {summaryData.summary}
              </DialogDescription>
            </div>
            <div className="text-center">
              {summaryData.summary_created_date}
              {" | "}
              {summaryData.summary_created_time}
            </div>
          </div>
        ) : (
          <div className="mt-4 text-center text-gray-500">
            <p>"No summary available at the moment"</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SummaryModal;
