import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import { Book, BotIcon, Menu } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";

import { ModeToggle } from "@/components/mode-toggle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store/store";
import { setAccessToken, setRefreshToken } from "@/redux/services/authSlice";
import { AuthEndPoints } from "@/api/endpoints";
import axiosApi from "@/utils/axiosInterceptor";
import { toast } from "sonner";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";

const Layout = () => {
  const userdetails = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      const response = await axiosApi.get(
        `${import.meta.env.VITE_SERVER_URL}${AuthEndPoints.LOGOUT}`,
      );
      if (response.data.status == 200) {
        dispatch(setAccessToken(null)), dispatch(setRefreshToken(null));
      }
    } catch (err) {
      toast.error("Internal error");
    }
  };
  return (
    <div className="grid  w-full md:grid-cols-[200px_1fr] lg:grid-cols-[200px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-14 items-center  px-4 lg:h-[60px] ">
            <div className="flex items-center gap-2 font-semibold">
              <BotIcon className="h-6 w-6" />
              <span className="">Nexby</span>
            </div>
            <ModeToggle />
          </div>
          <div className="flex-1 ">
            <nav className="grid items-start  px-2 text-sm font-medium lg:px-4">
              <ScrollArea>
                {userdetails.tab_permissions.length > 0 ? (
                  <Sidebar tabData={userdetails?.tab_permissions} />
                ) : (
                  <div>
                    <Skeleton className="mb-2 h-9" />
                    <Skeleton className="mb-2 h-9" />
                    <Skeleton className="mb-2 h-9" />
                    <Skeleton className="mb-2 h-9" />
                    <Skeleton className="mb-2 h-9" />
                  </div>
                )}
              </ScrollArea>
            </nav>
          </div>
          <div className="mb-2 grid cursor-pointer items-start px-2  font-medium lg:px-4">
            <div className="mx-[-0.65rem] mb-5 flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground">
              <Book className="h-5 w-5" />
              Documentation
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                {
                  userdetails.tab_permissions.length > 0 ? (
                    <div className="flex items-center gap-4 rounded-md text-muted-foreground hover:text-foreground">
                      <button
                        className={`text-neutral-content flex h-5 w-5 items-center justify-center  rounded-full  bg-blue-400 font-extrabold hover:bg-slate-700`}
                      >
                        <span className=" text-center font-serif uppercase ">
                          {userdetails.first_name
                            ? userdetails.first_name[0].toUpperCase()
                            : "0"}
                        </span>
                      </button>

                      <div className="max-w-40">
                        {userdetails.first_name} {userdetails.last_name}
                      </div>
                    </div>
                  ) : null
                  // <Skeleton className="mb-2 h-9" />
                }
              </DropdownMenuTrigger>

              <DropdownMenuContent align="center" className="w-44">
                <DropdownMenuItem
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 sm:flex md:hidden lg:hidden">
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="shrink-0 md:hidden"
              >
                <Menu className="h-5 w-5" />
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="flex flex-col">
              <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
                <div className="flex items-center gap-2 font-semibold">
                  <BotIcon className="h-6 w-6" />
                  <span className="">Nexby</span>
                </div>
                <ModeToggle />
              </div>
              <div className="flex-1 ">
                <nav className="grid gap-2 text-lg font-medium">
                  {
                    userdetails.tab_permissions.length > 0 ? (
                      <Sidebar tabData={userdetails?.tab_permissions} />
                    ) : null
                    // <Skeleton className="w-full" />
                  }
                </nav>
              </div>
              <div className="mb-2 grid  items-start  font-medium lg:px-4">
                <div className="mx-[-0.65rem] flex items-center gap-4 rounded-xl px-2 py-2 text-muted-foreground hover:text-foreground">
                  <Book className="h-5 w-5" />
                  Documentation
                </div>

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    {userdetails.tab_permissions.length > 0 ? (
                      <div className="flex items-center gap-4 rounded-md text-muted-foreground hover:text-foreground">
                        <button
                          className={`text-neutral-content flex h-5 w-5 items-center justify-center  rounded-full  bg-blue-400 font-extrabold hover:bg-slate-700`}
                        >
                          <span className=" text-center font-serif uppercase ">
                            {userdetails.first_name
                              ? userdetails.first_name[0].toUpperCase()
                              : "0"}
                          </span>
                        </button>

                        <div className="max-w-40">
                          {userdetails.first_name} {userdetails.last_name}
                        </div>
                      </div>
                    ) : (
                      <Skeleton className="mb-2 h-10" />
                    )}
                  </DropdownMenuTrigger>

                  <DropdownMenuContent align="center" className="w-44">
                    <DropdownMenuItem
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Logout
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </SheetContent>
          </Sheet>
        </header>
        <main className="flex flex-1 flex-col overflow-hidden">
          {/* Scrollable Outlet */}
          <ScrollArea className="h-screen">
            <Outlet />
          </ScrollArea>
        </main>
      </div>
    </div>
  );
};

export default Layout;
