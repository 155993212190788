import React from "react";

// Define a custom hook named UseDebouncing.
// This hook takes two generic type parameters: T (type of value) and U (type of delay).
const UseDebouncing = <T>(value: T, delay?: number): T => {
  // Initialize the debouncedValue state with the provided value.
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

  // Use the useEffect hook to set up the debouncing logic.
  React.useEffect(() => {
    // Set a timer that updates the debouncedValue after the specified delay (or default to 500ms).
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    // Clean up the timer when the component unmounts or when the value/delay changes.
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]); // This effect depends on the value and delay variables.

  // Return the debounced value to the component using this hook.
  return debouncedValue;
};

// Export the UseDebouncing custom hook.
export default UseDebouncing;
