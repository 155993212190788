import React from "react";
import comingSoonImage from "../../../../assets/underConstruction.gif";
import { useNavigate } from "react-router-dom";

const ComingSoon: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className="flex min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${comingSoonImage})` }}
    >
      <div className="p-8 text-center">
        <h1 className="mb-4 text-4xl font-bold ">Coming Soon ...</h1>
        <p>We're working on something exciting. Stay tuned!</p>
        {/* You can add more content or styling as needed */}
        <button
          className="mt-4 rounded-full bg-blue-500 px-6 py-2 text-white hover:bg-blue-600 focus:border-blue-300 focus:outline-none focus:ring"
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ComingSoon;
