import { useState, FC } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import axiosApi from "@/utils/axiosInterceptor";
import { toast } from "sonner";
import useSWR from "swr";
import { Loader } from "@/components/ui/loader";
import { Textarea } from "@/components/ui/textarea";

interface IProjectData {
  title: string;
  description: string;
  started_at: string;
  ended_at: string;
  project_tax_id: string;
  project_legal_id: string;
}

interface IProjectForm {
  geturl?: string;
  className?: string;
  callback?: () => void;
}

const ProjectForm: FC<IProjectForm> = ({ className, geturl, callback }) => {
  const url = geturl
    ? `${import.meta.env.VITE_SERVER_URL}${geturl}`
    : `${import.meta.env.VITE_SERVER_URL}`;
  const [editMode, setEditMode] = useState(geturl ? false : true);

  const projectValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    started_at: Yup.string().required("Start date is required"),
    ended_at: Yup.string().required("End date is required"),
    project_tax_id: Yup.string().required("Project Tax Id"),
    project_legal_id: Yup.string().required("Project Legal Id"),
  });

  const getProject = async () => {
    try {
      const response = await axiosApi.get(url);
      return response.data.success;
    } catch (e) {
      console.log(e);
    }
  };

  let swrKey = url;

  if (geturl) {
    swrKey = geturl;
  }

  const { data, error, isLoading } = useSWR(swrKey, geturl ? getProject : null);
  const initialData: IProjectData = {
    title: data ? data.title : "",
    description: data ? data.description : "",
    started_at: data ? data.started_at?.date : "",
    ended_at: data ? data.ended_at?.date : "",
    project_tax_id: data ? data.project_tax_id : "",
    project_legal_id: data ? data.project_legal_id : "",
  };

  const handleSubmitData = async (values, method, setSubmitting) => {
    try {
      setSubmitting(true);
      let response;
      if (method === "POST") {
        response = await axiosApi.post(
          `${url}/api/v1/builder/project/add/`,
          values,
        );
      } else if (method === "PUT") {
        response = await axiosApi.put(`${url}/update/`, values);
      }
      setSubmitting(false);
      setEditMode(false);
      toast.success(response.data.message);
      if (callback) {
        callback();
      }
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <div className={cn("rounded-xl border shadow-lg", className)}>
      <div className="flex h-14 items-center border-b lg:h-[60px]">
        <p className="font-bold capitalize">Project details</p>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={projectValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const editedData = Object.keys(values).reduce((acc, key) => {
            if (initialData[key] !== values[key]) {
              acc[key] = values[key];
            }
            return acc;
          }, {});
          handleSubmitData(
            data ? editedData : values,
            data ? "PUT" : "POST",
            setSubmitting,
          );
        }}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form className="mt-2">
            <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
              {/* Title */}
              <div className="my-2 text-black dark:text-white">
                <p className="pb-1 text-sm capitalize">Title</p>
                <Input
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!editMode}
                  className={`${geturl && !editMode ? "text-base" : ""} `}
                  type="text"
                  id="title"
                  name="title"
                />
                <ErrorMessage
                  name="title"
                  render={(msg) => (
                    <div className="text-xs text-red-500">{msg}</div>
                  )}
                />
              </div>

              {/* Description */}
              <div className="my-2 text-black dark:text-white">
                <p className="pb-1 text-sm capitalize">Description</p>
                <Textarea
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!editMode}
                  className={`${geturl && !editMode ? "text-base" : ""} `}
                  id="description"
                  name="description"
                />
                <ErrorMessage
                  name="description"
                  render={(msg) => (
                    <div className="text-xs text-red-500">{msg}</div>
                  )}
                />
              </div>
              {/* Started At */}
              <div className="my-2 text-black dark:text-white">
                <p className="pb-1 text-sm capitalize">Started At</p>
                <Input
                  defaultValue={initialData.started_at}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!editMode}
                  className={`${geturl && !editMode ? " text-base" : ""} `}
                  type="text"
                  id="started_at"
                  name="started_at"
                />
                <ErrorMessage
                  name="started_at"
                  render={(msg) => (
                    <div className="text-xs text-red-500">{msg}</div>
                  )}
                />
              </div>

              {/* Ended At */}
              <div className="my-2 text-black dark:text-white">
                <p className="pb-1 text-sm capitalize">Ended At</p>
                <Input
                  defaultValue={initialData.ended_at}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!editMode}
                  className={`${geturl && !editMode ? " text-base" : ""} `}
                  type="text"
                  id="ended_at"
                  name="ended_at"
                />
                <ErrorMessage
                  name="ended_at"
                  render={(msg) => (
                    <div className="text-xs text-red-500">{msg}</div>
                  )}
                />
              </div>

              <div className="my-2 text-black dark:text-white">
                <p className="pb-1 text-sm capitalize">Project Tax Id</p>
                <Input
                  value={values.project_tax_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!editMode}
                  className={`${geturl && !editMode ? "text-base" : ""} `}
                  type="text"
                  id="project_tax_id"
                  name="project_tax_id"
                />
                <ErrorMessage
                  name="project_tax_id"
                  render={(msg) => (
                    <div className="text-xs text-red-500">{msg}</div>
                  )}
                />
              </div>
              <div className="my-2 text-black dark:text-white">
                <p className="pb-1 text-sm capitalize">Project Legal Id</p>
                <Input
                  value={values.project_legal_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!editMode}
                  className={`${geturl && !editMode ? "text-base" : ""} `}
                  type="text"
                  id="project_legal_id"
                  name="project_legal_id"
                />
                <ErrorMessage
                  name="project_legal_id"
                  render={(msg) => (
                    <div className="text-xs text-red-500">{msg}</div>
                  )}
                />
              </div>
            </div>
            <div className="mt-2 flex justify-end">
              {editMode && (
                <Button variant={"outline"} type="submit">
                  {!isSubmitting && "Submit"}
                  {isSubmitting && <Loader size={20} colors="" />}
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>

      {geturl && !editMode && (
        <Button variant={"outline"} onClick={() => setEditMode(true)}>
          Edit
        </Button>
      )}
      {error && <div className="text-red-500">Error loading data.</div>}
    </div>
  );
};

export default ProjectForm;
