import { Dialog, DialogContent } from "@/components/ui/dialog";
import AddUserForm from "./add-customer-form";

const AddCustomerModal = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-7xl">
        <AddUserForm className="border-none shadow-none" />
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomerModal;
