import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  accessToken: sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : null,
  refreshToken: localStorage.getItem("refreshToken")
    ? localStorage.getItem("refreshToken")
    : null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      if (action.payload == null) sessionStorage.removeItem("token");
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string | null>) => {
      if (action.payload == null) localStorage.removeItem("refreshToken");
      state.refreshToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccessToken, setRefreshToken } = authSlice.actions;

export default authSlice.reducer;
