import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "@/redux/store/store";
import { setAccessToken, setRefreshToken } from "@/redux/services/authSlice";
import { redirect } from "react-router-dom";
import { toast } from "sonner";
const axiosApi = axios.create();

export type ErrorResponse = {
  error?: string;
};

let isRefreshing = false;
let refreshQueue: (() => void)[] = [];

const processQueue = (
  error: AxiosError | null,
  token: string | null = null,
) => {
  refreshQueue.forEach((resolve) => {
    if (!error && token) {
      // Update the authorization header with the new token
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    resolve();
  });
  refreshQueue = [];
};

axiosApi.interceptors.request.use((config) => {
  const initialToken = sessionStorage.getItem("token");
  config.headers["Authorization"] = initialToken?.startsWith("Bearer ")
    ? initialToken
    : `Bearer ${initialToken}`;
  return config;
});

axiosApi.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with the successful response data
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        toast.error("Unauthorized access. Please log in again.");
      } else if (error.response.data && error.response.data.error !== "") {
        toast.error(error.response.data.error);
      } else {
        console.log("errr", error);
        toast.error(error.message);
      }
    } else {
      // Handle other errors
      toast.error("An error occurred. Please try again later.");
    }

    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve) {
          refreshQueue.push(function () {
            resolve(axiosApi(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        const refreshToken = store.getState().auth.refreshToken;

        axios
          .post(`${import.meta.env.VITE_SERVER_URL}/api/v1/user/renew-token/`, {
            refresh: refreshToken,
          })
          .then(({ data }) => {
            sessionStorage.setItem("token", data.access);
            localStorage.setItem("refreshToken", data.refresh);
            store.dispatch(setAccessToken(data.access));
            store.dispatch(setRefreshToken(data.refresh));
            isRefreshing = false;
            processQueue(null, data.access);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            store.dispatch(setAccessToken(null));
            store.dispatch(setRefreshToken(null));
            redirect("/auth");
            processQueue(err, null);
            reject(err);
          });
      });
    }

    // Handle other errors
    return Promise.reject(error);
  },
);

export default axiosApi;
