import { Skeleton } from "@/components/ui/skeleton";
import {
  BarChartIcon,
  BellRing,
  Building2,
  CalendarClockIcon,
  CreditCardIcon,
  LayoutDashboardIcon,
  MessageCircleIcon,
  SquareDashedBottomCode,
  User2Icon,
  UsersIcon,
} from "lucide-react";
import { createElement } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ tabData }) => {
  const IconsMap = {
    DASHBOARD: LayoutDashboardIcon,
    STAFF: UsersIcon,
    WHATSAPPBOT: MessageCircleIcon,
    PROJECTS: Building2,
    FOLLOWUP: CalendarClockIcon,
    BILLING: CreditCardIcon,
    REMINDER: BellRing,
    PROFILE: User2Icon,
    COMINGSOON: SquareDashedBottomCode,
    REPORTS: BarChartIcon,
  };
  const generateNavLink = (path, icon, label) => (
    <NavLink
      to={path}
      className={({ isActive }) =>
        `${isActive ? "bg-zinc-600" : "text-muted-foreground"}`
      }
    >
      <div className="mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground">
        {icon && createElement(icon, { className: "h-5 w-5" })}
        <p className=" capitalize">{label.toLowerCase()}</p>
      </div>
    </NavLink>
  );

  return (
    <>
      {/* <Skeleton className="h-12" /> */}
      {tabData?.map((value, index) => {
        const mainKey = Object.keys(value).find((key) => key !== "id");
        return (
          <div key={index}>
            {mainKey &&
              generateNavLink(
                `/pages/${mainKey.toLowerCase()}`,
                IconsMap[mainKey],
                mainKey,
              )}
          </div>
        );
      })}
    </>
  );
};

export default Sidebar;
