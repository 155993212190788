import { NexByDark, NexByLight } from "@/assets";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store/store";

export const Footer = () => {
  const { currentTheme } = useSelector((state: RootState) => state.theme);
  return (
    <footer id="footer">
      <hr className="mx-auto w-11/12 border" />

      <section className="container grid grid-cols-2 gap-x-12 gap-y-8 py-20 md:grid-cols-4 xl:grid-cols-6">
        <div className="col-span-full xl:col-span-2">
          <img
            className="h-6"
            src={currentTheme === "dark" ? NexByLight : NexByDark}
            alt="logo"
          />
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-lg font-bold">Follow US</h3>
          {/* <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Github
            </a>
          </div> */}

          <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Twitter
            </a>
          </div>

          {/* <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Dribbble
            </a>
          </div> */}
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-lg font-bold">Platforms</h3>
          <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Web
            </a>
          </div>

          {/* <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Mobile
            </a>
          </div>

          <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Desktop
            </a>
          </div> */}
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-lg font-bold">About</h3>
          <div>
            <a href="#features" className="opacity-60 hover:opacity-100">
              Features
            </a>
          </div>

          <div>
            <a href="#team" className="opacity-60 hover:opacity-100">
              Team
            </a>
          </div>

          <div>
            <a href="#faq" className="opacity-60 hover:opacity-100">
              FAQ
            </a>
          </div>
        </div>

        {/* <div className="flex flex-col gap-2">
          <h3 className="text-lg font-bold">Community</h3>
          <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Youtube
            </a>
          </div>

          <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Discord
            </a>
          </div>

          <div>
            <a href="#" className="opacity-60 hover:opacity-100">
              Twitch
            </a>
          </div>
        </div> */}
      </section>

      <section className="container pb-14 text-center">
        <h3 className="flex items-center justify-center">
          &copy; 2024 Powerd by{" "}
          <a
            target="_blank"
            href="https://github.com/leoMirandaa"
            className="border-primary text-primary transition-all hover:border-b-2"
          >
            <img
              className="ml-2 h-6"
              src={currentTheme === "dark" ? NexByLight : NexByDark}
              alt="logo"
            />
          </a>
        </h3>
      </section>
    </footer>
  );
};
