import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "../../components/ui/button";
import { NexByDark, NexByLight } from "@/assets";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { AuthEndPoints } from "@/api/endpoints";
import { Loader } from "@/components/ui/loader";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch hook
import {
  setAccessToken,
  setRefreshToken,
} from "../../redux/services/authSlice";
import { useState } from "react";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { RootState } from "@/redux/store/store";

interface ILogin {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { currentTheme } = useSelector((state: RootState) => state.theme);
  const initialValues = {
    email: "",
    password: "",
  };
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Required"),
    password: Yup.string()
      .matches(
        passwordRegex,
        "Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long",
      )
      .required("Required"),
  });

  const handleLogin = async (
    userDetails: ILogin,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_SERVER_URL + AuthEndPoints.LOGIN}`,
        { ...userDetails },
        { withCredentials: true },
      );
      const { access, refresh } = response.data.success;
      dispatch(setAccessToken(access)); // Dispatch Redux action to set access token
      dispatch(setRefreshToken(refresh));
      sessionStorage.setItem("token", access);
      localStorage.setItem("refreshToken", refresh);
      navigate("/pages/");
      toast(response.data.message);
      setSubmitting(false);
    } catch (error) {
      toast.error("error");
      setSubmitting(false);
    }
  };
  const getRandomNumber = () => Math.floor(Math.random() * 9) + 1;

  const imageUrl = `https://nexby-document-storage.s3.ap-south-1.amazonaws.com/static-assests/${getRandomNumber()}.jpg`;
  return (
    <div className="relative h-screen flex flex-col lg:flex-row lg:max-w-none lg:px-0">
      <div className="w-full lg:w-3/5 relative hidden lg:block">
        {/* Image div taking 60% width */}
        <div className="h-full bg-muted p-10 text-white dark:border-r">
          <div className="absolute inset-0 overflow-hidden">
            <img
              src={imageUrl}
              alt="Random"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="relative z-20 flex items-start justify-start h-3/4">
            <img src={NexByLight} alt="" width={150} height={150} />
          </div>
          {/* quote to the end of the component */}
          <div className="absolute bottom-0 left-0 right-0 p-10">
            <p className="text-lg font-semibold">
              &quot;The best way to predict the future is to create it.&quot;
            </p>
            <p className="text-sm text-muted-foreground">- Peter Drucker</p>
          </div>
        </div>
      </div>
      <div className="w-full h-screen  lg:w-2/5 p-4 lg:p-8 flex flex-col justify-center items-center ">
        <div className="flex w-full max-w-sm flex-col justify-center md:mx-auto md:w-96">
          <div className="mb-5 text-center">
            <div className="text-center">
              <h5 className="p-3 text-xl font-bold">Login</h5>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleLogin(values, setSubmitting);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className=" grid w-full max-w-md grid-cols-1 gap-2"
              >
                {/* Email */}
                <div>
                  <label className="">Email</label>
                  <Input
                    placeholder="Enter your email address "
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>
                <ErrorMessage
                  name={`email`}
                  render={(msg) => (
                    <div className="text-xs text-red-500">{msg}</div>
                  )}
                />

                {/* Password */}
                <div>
                  <label>Password</label>
                  <div className="flex items-center rounded-md border pr-1 shadow-sm focus-visible:ring-1">
                    <Input
                      placeholder="Enter your password "
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="border-none focus-visible:ring-0"
                      value={values.password}
                    />
                    <button
                      type="button"
                      className="ml-2 focus:outline-none"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOffIcon className="text-gray-500" />
                      ) : (
                        <EyeIcon className="text-gray-500" />
                      )}
                    </button>
                  </div>
                </div>
                <ErrorMessage
                  name={`password`}
                  render={(msg) => (
                    <div className="text-xs text-red-500">{msg}</div>
                  )}
                />

                <Button type="submit" disabled={isSubmitting} className="mt-2">
                  {!isSubmitting && "Log In"}
                  {isSubmitting && <Loader size={20} colors=""></Loader>}
                </Button>
              </form>
            )}
          </Formik>
          <div className="mt-4 pt-3 text-center">
            <p className="">
              Don't have an account ?{" "}
              <Link to="/auth/register" className="text-blue-500">
                Register
              </Link>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
