import { Route, Routes } from "react-router-dom";
import Register from "./register";
import Login from "./login";
import ForgetPassword from "./forgetpassword";
import ErrorPage from "../error-page";
import Page404 from "../page404";
const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} errorElement={<ErrorPage />} />
      <Route
        path="/register"
        element={<Register />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/forget-password"
        element={<ForgetPassword />}
        errorElement={<ErrorPage />}
      />
      <Route path="/*" element={<Page404 />} />
    </Routes>
  );
};

export default AuthRoutes;
