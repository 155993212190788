import { FC, useState } from "react";
import { cn } from "@/lib/utils";
import axiosApi from "@/utils/axiosInterceptor";
import useSWR from "swr";
import { Loader } from "@/components/ui/loader";
import { useParams } from "react-router-dom";
import FollowUpModal from "./follow-up-modal";
import CTable from "@/components/ctable/data-table";
import DocViewer from "react-doc-viewer";
import {
  File,
  FileSpreadsheetIcon,
  FileTextIcon,
  ImageIcon,
} from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";

interface IFollowUpView {
  geturl?: string;
  className?: string;
}

const FollowUpView: FC<IFollowUpView> = ({ className }) => {
  const { followupStatus, projectId } = useParams();
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState("");
  const getProject = async (url) => {
    try {
      const response = await axiosApi.get(url);
      if (response.data.status == 200) {
        return response.data.success;
      } else {
        throw Error
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { data, error, isLoading } = useSWR(
    `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/project/${projectId}/followup/`,
    getProject,
  );
  const columns = [
    {
      header: "Message",
      accessorKey: "message",
    },
    {
      header: "Trigger Type",
      accessorKey: "trigger_type",
    },
    {
      header: "Trigger Value",
      accessorKey: "trigger_value",
    },
    {
      header: "Attachment",
      accessorKey: "file",
      cell: ({ row }) => {
        const fileType = row.original;
        if (fileType) {
          const fileExtension = row.original.file
            .split(".")
            .pop()
            .split("?")[0];
          let icon;
          if (fileExtension === "pdf") {
            icon = (
              <>
                {/* <DocViewer documents={row.original.file} /> */}
                <FileTextIcon
                  className="cursor-pointer text-red-400"
                  size={24}
                />
              </>
            ); // Adjust size as needed
          } else if (fileExtension === "xlsx") {
            icon = (
              <FileSpreadsheetIcon
                className="cursor-pointer text-green-400"
                size={24}
              />
            ); // Adjust size as needed
          } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
            icon = (
              <ImageIcon className="cursor-pointer text-blue-400 " size={24} />
            ); // Adjust size as needed
          } else {
            // Render a default icon for other file types
            icon = <File className="cursor-pointer" size={24} />; // Adjust size as needed
          }

          return icon;
        } else {
          // Render other file types accordingly, e.g., PDF viewer, document viewer, etc.
          return <div>File Preview Not Available</div>;
        }
      },
    },
    {
      id: "select",
      header: "Action",
      cell: ({ row }) => (
        <div
          className=" text-md  cursor-pointer text-blue-500 underline underline-offset-2"
          onClick={() => {
            setEdit(true);
            setEditValue(row.original);
          }}
        >
          Edit
        </div>
      ),
    },
  ];

  return (
    <div className={cn("rounded-xl border shadow-lg", className)}>
      <div className="mb-2 flex  h-14 items-center border-b lg:h-[60px]">
        <p className="font-bold capitalize"> Follow-Up</p>
      </div>
      {edit && editValue && (
        <FollowUpModal
          isOpen={edit}
          onClose={() => setEdit(false)}
          editvalue={editValue}
          onSubmit={(e) => console.log(e)}
        />
      )}

      {!followupStatus && (
        <p className=" italic text-red-500">Follow is not approved yet</p>
      )}
      {followupStatus && (
        <div>
          {!isLoading && data && <CTable data={data} columns={columns} />}
          {isLoading && (
            <div className="mt-4">
              <div className="flex ">
                <Skeleton className="h-9 w-[150px] lg:w-[250px]" />
                <Skeleton className="ml-4 h-9 w-16" />
                <Skeleton className="ml-auto h-9 w-16" />
              </div>
              <div className="mt-4">
                <Skeleton className="h-10" />
                <Skeleton className="mt-2 h-10" />
                <Skeleton className="mt-2 h-10" />
                <Skeleton className="mt-2 h-10" />
                <Skeleton className="mt-2 h-10" />
              </div>
              <p className="mt-5">Loading ...</p>
            </div>
          )}
          {error && <div className="text-red-500">Error loading data.</div>}
        </div>
      )}
    </div>
  );
};

export default FollowUpView;
