import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Loader } from "@/components/ui/loader";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import axiosApi from "@/utils/axiosInterceptor";
import { ErrorMessage, Form, Formik } from "formik";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import useSWR from "swr";
import * as Yup from "yup";

interface ITemplateData {
  message: string;
  title: string;
}

interface ITemplateForm {
  className?: string;
  geturl?: string;
  callback?: () => void;
}

const TemplateForm: FC<ITemplateForm> = ({ className, geturl, callback }) => {
  const { projectId } = useParams();
  const url = geturl
    ? `${import.meta.env.VITE_SERVER_URL}${geturl}`
    : `${import.meta.env.VITE_SERVER_URL}`;

  const [editMode, setEditMode] = useState(geturl ? false : true);

  // /api/v1/builder/project/{{project_id}}/template/1/
  const getTemplate = async () => {
    try {
      const response = await axiosApi.get(url);
      return response.data.success;
    } catch (e) {
      console.log(e);
    }
  };

  let swrKey = url;

  if (geturl) {
    swrKey = geturl;
  }

  const { data, error, isLoading } = useSWR(
    swrKey,
    geturl ? getTemplate : null,
  );

  const handleSubmitData = async (values, method, setSubmitting) => {
    try {
      setSubmitting(true);
      let response;
      if (method === "POST") {
        response = await axiosApi.post(
          `${url}/api/v1/builder/project/${projectId}/template/add/`,
          values,
        );
      } else if (method === "PUT") {
        response = await axiosApi.put(`${url}update/`, values);
      }
      setSubmitting(false);
      setEditMode(false);
      toast.success(response.data.message);
      if (callback) {
        callback();
      }
    } catch (e) {
      setSubmitting(false);
    }
  };

  const TemplateValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    message: Yup.string().required("Message is required"),
  });

  const initialData: ITemplateData = {
    message: data ? data.message : "",
    title: data ? data.title : "",
  };
  return (
    <>
      <div className={cn("my-8 rounded-xl border p-4 shadow-lg", className)}>
        <p className="mb-1 font-bold underline underline-offset-2 dark:text-white">
          Welcome Message Template (WhatsApp)
        </p>
        <p className="text-sm font-light italic text-gray-500">
          This template can be used for sending welcome message to a new lead
        </p>
        <Formik
          enableReinitialize
          initialValues={initialData}
          validationSchema={TemplateValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const editedData = Object.keys(values).reduce((acc, key) => {
              if (initialData[key] !== values[key]) {
                acc[key] = values[key];
              }
              return acc;
            }, {});
            handleSubmitData(
              data ? editedData : values,
              data ? "PUT" : "POST",
              setSubmitting,
            );
          }}
        >
          {({ values, handleChange, handleBlur, isSubmitting }) => (
            <Form className="">
              <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 ">
                <div className="my-2 text-black dark:text-white">
                  <p className="pb-1 text-sm capitalize">Title</p>
                  <Input
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    id={"title"}
                    name={"title"}
                  />
                  <ErrorMessage
                    name={"title"}
                    render={(msg) => (
                      <div className="text-xs text-red-500">{msg}</div>
                    )}
                  />
                </div>
                <div className="my-2 text-black dark:text-white md:col-span-2">
                  <p className="pb-1 text-sm capitalize">Message</p>
                  <Textarea
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id={"message"}
                    name={"message"}
                  />
                  <ErrorMessage
                    name={"message"}
                    render={(msg) => (
                      <div className="text-xs text-red-500">{msg}</div>
                    )}
                  />
                </div>
              </div>
              <p className="text-sm">Tips to draft a message on WhatsApp:</p>
              <ul className="pl-4 text-sm">
                <li>Use asterisks (*) to bold text: *your text*</li>
                <li>Use underscores (_) to italicize text: _your text_</li>
                <li>Use tilde (~) to strikethrough text: ~your text~</li>
                {/* <li>Create hyperlinks using angle brackets (<>): &lt;https://www.example.com&gt;</li> */}
                {/* Add more bullet points for additional tips */}
              </ul>
              <div className="mt-2 flex justify-end">
                <Button type="submit">
                  {!isSubmitting && "Submit"}
                  {isSubmitting && <Loader size={20} colors="" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default TemplateForm;
