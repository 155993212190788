import { Cross2Icon, UploadIcon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { DataTableViewOptions } from "./data-table-view-options";
import { statuses } from "./data/data";
import { DatePickerWithRange } from "../date-picker/date-range-picker";
import CustomDropdown from "../CDropdown";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  onExport?: () => void;
  searchFilter: string;
  searchPlaceholder?: string;
  dateFn?: (dateValue: string) => void;
  statusFn?: (status: [string]) => void;
  searchFilterFn: (value: string) => void;
  disabledDate?: {
    after: any;
    before: any;
  };
}

export function DataTableToolbar<TData>({
  table,
  searchFilter,
  searchFilterFn,
  searchPlaceholder,
  dateFn,
  statusFn,
  onExport,
  disabledDate,
}: DataTableToolbarProps<TData>) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {searchFilterFn && (
          <Input
            placeholder={
              searchPlaceholder ? searchPlaceholder : "seacrh here..."
            }
            value={searchFilter}
            onChange={(event) => searchFilterFn(event.target.value)}
            className="h-9 w-[150px] lg:w-[250px]"
          />
        )}
        {dateFn && (
          <DatePickerWithRange
            selectedDate={dateFn}
            disabledDays={disabledDate}
          />
        )}

        {statusFn && (
          <CustomDropdown
            title="status"
            options={statuses}
            displayKey="label"
            getValue="value"
            getDataCallback={(e) => statusFn(e)}
            editOptions={[]}
          ></CustomDropdown>
        )}
        {searchFilter && (
          <Button
            variant="ghost"
            onClick={() => searchFilterFn("")}
            className="h-9 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
        {onExport && (
          <Button
            variant="outline"
            size="sm"
            className="ml-auto mr-2 hidden h-9 lg:flex"
            onClick={onExport}
          >
            <UploadIcon className="mr-2 h-4 w-4" />
            Export
          </Button>
        )}
      </div>

      <DataTableViewOptions table={table} />
    </div>
  );
}
