import { setTheme } from "@/redux/services/themeSlice";
import { RootState } from "@/redux/store/store";
import { Moon, Sun } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
export function ModeToggle() {
  const dispatch = useDispatch();
  const { currentTheme } = useSelector((state: RootState) => state.theme);
  return (
    <label className="relative ml-auto  mr-4 inline-flex cursor-pointer items-center">
      <input
        type="checkbox"
        className="sr-only"
        checked={currentTheme === "light"}
        onChange={() => {
          if (currentTheme === "light") {
            dispatch(setTheme("dark"));
          } else {
            dispatch(setTheme("light"));
          }
        }}
      />
      <div className="ml-3 font-medium text-gray-700">
        {currentTheme === "light" ? (
          <Sun className="text-yellow-500" />
        ) : (
          <Moon className="text-gray-200" />
        )}
      </div>
    </label>
  );
}
