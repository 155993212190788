import React from "react";

interface CustomLoaderProps {
  size: number;
  colors: string;
}

export const Loader: React.FC<CustomLoaderProps> = ({ size, colors }) => {
  const baseStyle: React.CSSProperties = {
    width: size,
    height: size,
    borderRadius: "50%",
    borderTop: `2px solid ${colors}`,
    borderBottom: `2px solid ${colors}`,
    animation: "spin 1.5s linear infinite",
  };

  const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div className="flex items-center justify-center">
      <style>{keyframes}</style>
      <div style={baseStyle} />
    </div>
  );
};

export const ThreeCircleLoader: React.FC<CustomLoaderProps> = ({
  size,
  colors,
}) => {
  const circleStyle: React.CSSProperties = {
    marginRight: "8px",
    width: size,
    height: size,
    borderRadius: "50%",
    background: colors,
    animation: "bounce 1s infinite alternate",
  };

  const keyframes = `
    @keyframes bounce {
      0% {
        transform: translateY(0) scale(1);
      }
      100% {
        transform: translateY(-8px) scale(1.6);
      }
    }
  `;

  return (
    <div className="flex items-center justify-center">
      <style>{keyframes}</style>
      <div style={circleStyle} />
      <div style={{ ...circleStyle, animationDelay: "0.2s" }} />
      <div style={{ ...circleStyle, animationDelay: "0.4s" }} />
    </div>
  );
};

export const TripleDotLoader: React.FC<CustomLoaderProps> = ({
  size,
  colors,
}) => {
  const loaderTextStyle: React.CSSProperties = {
    color: colors,
    fontSize: size,
  };

  const keyframes = `
    @keyframes loading-text {
      0% {
        content: "Loading";
      }
      25% {
        content: "Loading.";
      }
      50% {
        content: "Loading..";
      }
      75% {
        content: "Loading...";
      }
    }
  `;

  return (
    <div className="relative mx-auto">
      <style>{keyframes}</style>
      <div className="loader--text" style={loaderTextStyle}>
        <div className="loader--text:after" />
      </div>
    </div>
  );
};

export const CSTableLoader: React.FC<CustomLoaderProps> = () => {
  return (
    <div className="flex h-56 w-full items-center justify-center rounded-lg border border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
      <div className="text-md animate-pulse rounded bg-blue-200 px-6 py-3 text-center font-medium leading-none text-blue-800 dark:bg-blue-900 dark:text-blue-200">
        "Please wait Almost there!"
      </div>
    </div>
  );
};
