import { MagnifierIcon, WalletIcon, ChartIcon } from "./Icons";
import cubeLeg from "@/assets/cube-leg.png";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface ServiceProps {
  title: string;
  description: string;
  icon: JSX.Element;
}

const serviceList: ServiceProps[] = [
  {
    title: "Code Collaboration",
    description:
      "With intelligent lead qualification, personalized customer interactions, predictive analytics, seamless integration, and continuous improvement, our solution empowers your sales team to excel and drive unprecedented growth.",
    icon: <ChartIcon />,
  },
  {
    title: "Project Management",
    description:
      "Are you ready to revolutionize your sales process and unlock new opportunities for growth? Contact us today to learn more about Generative Powered Sales Agent and how it can transform your business.",
    icon: <WalletIcon />,
  },
  {
    title: "Task Automation",
    description:
      "Experience the power of Nexby's AI Sales Agent firsthand and unlock new opportunities for growth and success. Contact us today to schedule a demonstration and see how our solution can revolutionize your sales performance.",
    icon: <MagnifierIcon />,
  },
];

export const Services = () => {
  return (
    <section className="container py-24 sm:py-32">
      <div className="grid place-items-center gap-8 lg:grid-cols-[1fr,1fr]">
        <div>
          <h2 className="text-3xl font-bold md:text-4xl">
            <span className="bg-gradient-to-b from-primary/60 to-primary bg-clip-text text-transparent">
              A Closer Look At{" "}
            </span>
            Performance
          </h2>

          <p className="mb-8 mt-4 text-xl text-muted-foreground ">
            Delve deeper into Nexby's AI Sales Agent and discover its
            transformative capabilities for your business.
          </p>

          <div className="flex flex-col gap-8">
            {serviceList.map(({ icon, title, description }: ServiceProps) => (
              <Card key={title}>
                <CardHeader className="flex items-start justify-start gap-4 space-y-1 md:flex-row">
                  <div className="mt-1 rounded-2xl bg-primary/20 p-1">
                    {icon}
                  </div>
                  <div>
                    <CardTitle>{title}</CardTitle>
                    <CardDescription className="text-md mt-2">
                      {description}
                    </CardDescription>
                  </div>
                </CardHeader>
              </Card>
            ))}
          </div>
        </div>

        <img
          src={cubeLeg}
          className="w-[300px] object-contain md:w-[500px] lg:w-[600px]"
          alt="About services"
        />
      </div>
    </section>
  );
};
