import { Statistics } from "./Statistics";
import pilot from "@/assets/pilot.png";

export const About = () => {
  return (
    <section id="about" className="container py-24 sm:py-32">
      <div className="rounded-lg border bg-muted/50 py-12">
        <div className="flex flex-col-reverse gap-8 px-6 md:flex-row md:gap-12">
          <img
            src={pilot}
            alt=""
            className="w-[300px] rounded-lg object-contain"
          />
          <div className="bg-green-0 flex flex-col justify-between">
            <div className="pb-6">
              <h2 className="text-3xl font-bold md:text-4xl">
                <span className="bg-gradient-to-b from-primary/60 to-primary bg-clip-text text-transparent">
                  Why Choose{" "}
                </span>
                Generative Powered Sales Agent
              </h2>
              <ul className="mt-4 text-xl text-muted-foreground">
                <li role="list" className="mb-4 list-disc">
                  Increased Efficiency: With an AI-powered assistant handling
                  routine tasks and inquiries, your sales team can focus on
                  high-value activities that drive revenue.{" "}
                </li>
                <li role="list" className="my-4 list-disc">
                  Personalized Engagement: Leverage customer data to deliver
                  personalized interactions that resonate with your audience and
                  drive conversions.{" "}
                </li>
                <li role="list" className="mb-4 list-disc">
                  {" "}
                  Scalable Solutions: Whether you're a small startup or a
                  multinational corporation, our AI Sales Agent scales
                  effortlessly to meet your needs.{" "}
                </li>

                <li role="list" className="mb-4 list-disc">
                  Continuous Improvement: Our commitment to innovation means
                  your AI Sales Agent will only get smarter over time,
                  delivering even greater value to your organization.
                </li>
              </ul>
            </div>

            {/* <Statistics /> */}
          </div>
        </div>
      </div>
    </section>
  );
};
