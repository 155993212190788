import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RoleState {
  id: number;
  name: string;
}

interface TabPermission {
  [key: string]: {
    can_create: boolean;
    can_delete: boolean;
    can_download: boolean;
    can_export: boolean;
    can_import: boolean;
    can_read: boolean;
    can_update: boolean;
  };
}

export interface UserState {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  is_mobile_number_verified: boolean;
  is_active: boolean;
  is_admin?: boolean;
  is_staff?: boolean;
  is_builder?: boolean;
  profile_picture?: string;
  role: RoleState;
  tab_permissions: TabPermission[];
}

const initialState: UserState = {
  id: 0,
  first_name: "",
  last_name: "",
  email: "",
  mobile_number: "",
  is_active: false,
  is_mobile_number_verified: false,
  role: { id: 0, name: "" },
  tab_permissions: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      return { ...state, ...action.payload };
    },
    clearUser: (state) => {
      state.id = 0;
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
