import CTable from "@/components/ctable/data-table";
import { Skeleton } from "@/components/ui/skeleton";
import ErrorPage from "@/pages/error-page";
import axiosApi from "@/utils/axiosInterceptor";
import { useState } from "react";
import useSWR from "swr";
import CustomerChatModal from "./customer-chat-modal";
import { useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";

const CustomerView = ({ geturl }) => {
  const { projectId } = useParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [visible, setVisible] = useState(false);
  const [customerId, setCustomerID] = useState(0);
  const [customerName, setCustomerName] = useState(null);

  const getcustomer = async (url) => {
    try {
      const response = await axiosApi.get(url);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };
  const { data, error, isLoading, mutate } = useSWR(
    `${import.meta.env.VITE_SERVER_URL}${geturl}?page=${page}&items=${pageSize}`,
    getcustomer,
  );

  const handleChat = (value) => {
    setCustomerID(value.id);
    setCustomerName(value.full_name);
    setVisible(true);
  };

  if (error) return <ErrorPage />;
  const columns = [
    {
      header: "Sr.no",
      cell: ({ row }) => {
        return <div>{row.index + 1 + (page - 1) * 10}</div>;
      },
    },

    {
      header: "Name",
      accessorKey: "full_name",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Mobile",
      accessorKey: "mobile_number",
    },

    {
      header: "Channel",
      accessorKey: "channel",
    },

    {
      header: "Lead / Status",
      accessorKey: "status",
      cell: ({ row }) => {
        const handlestatus = async () => {
          try {
            await axiosApi.put(
              `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/project/${projectId}/customer/${row.original.id}/update/`,
              {
                status:
                  row.original.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
              },
            );
            mutate(
              `${import.meta.env.VITE_SERVER_URL}${geturl}?page=${page}&items=${pageSize}`,
            );
          } catch (e) {
            console.log(e);
          }
        };
        return (
          <div className="flex items-center">
            {row.original.status && (
              <p
                className={`p-1 text-xs ${row.original.lead_status === "OPEN" ? " text-red-400  dark:text-red-400" : " text-green-400 dark:text-green-400"}`}
              >
                {row.original.lead_status}
              </p>
            )}
            <p className=" text-gray-500">/</p>
            {row.original.status && (
              <p
                onClick={() => handlestatus()}
                className={`cursor-pointer p-1 text-xs ${row.original.status === "INACTIVE" ? " text-red-400  dark:text-red-400" : " text-green-400 dark:text-green-400"}`}
              >
                {row.original.status}
              </p>
            )}
          </div>
        );
      },
    },
    {
      id: "select",
      header: "Action",
      cell: ({ row }) => (
        <div
          className=" text-md  cursor-pointer text-blue-500 underline underline-offset-2"
          onClick={() => {
            handleChat(row.original);
          }}
        >
          View Chat
        </div>
      ),
    },
  ];
  return (
    <div>
      {visible && (
        <CustomerChatModal
          isOpen={visible}
          onClose={() => setVisible(false)}
          customerName={customerName}
          geturl={`${import.meta.env.VITE_SERVER_URL}/api/v1/builder/project/${projectId}/customer/${customerId}/chat/`}
        />
      )}
      <div className="flex h-14 items-center border-b lg:h-[60px]">
        <p className="font-bold capitalize">Customers</p>
        <Button
          variant={"outline"}
          className="ml-auto"
          onClick={() => console.log("add")}
        >
          Add Customer
        </Button>
      </div>
      {!isLoading && data.success ? (
        <div className="mt-4">
          <CTable
            searchPlaceholder="Search ..."
            data={data.success}
            columns={columns}
            handlePaginationClick={function (pageNumber: number): void {
              setPage(pageNumber);
            }}
            pageDetails={data.page_details}
            handlePageSize={(e) => setPageSize(e)}
          />
        </div>
      ) : !isLoading && !data.success ? (
        <div className="mt-4">
          <p>No customer is there yet</p>
        </div>
      ) : (
        <div className="mt-4">
          <div className="flex">
            <Skeleton className="h-9 w-[150px] lg:w-[250px]" />
            <Skeleton className="ml-4 h-9 w-16" />
            <Skeleton className="ml-auto h-9 w-16" />
          </div>
          <div className="mt-4">
            <Skeleton className="h-10" />
            <Skeleton className="mt-2 h-10" />
            <Skeleton className="mt-2 h-10" />
            <Skeleton className="mt-2 h-10" />
            <Skeleton className="mt-2 h-10" />
          </div>
          <p className="mt-5">Loading ...</p>
        </div>
      )}

    </div>
  );
};

export default CustomerView;
