import { WhatsAppBg } from "@/assets";
import { Button } from "@/components/ui/button";
import axiosApi from "@/utils/axiosInterceptor";
import useSWR from "swr";
import TemplateModal from "./template-modal";
import { useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";

const TemplateView = ({ projectId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [templateId, setTemplateId] = useState(0);
  const getTemplate = async (url) => {
    try {
      const response = await axiosApi.get(url);
      return response.data.success;
    } catch (e) {
      console.log(e);
    }
  };

  const { data, isLoading } = useSWR(
    `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/project/${projectId}/template/`,
    getTemplate,
  );

  return (
    <>
      <div className="">
        {isOpen && (
          <TemplateModal
            isOpen={isOpen}
            onClose={() => setIsOpen(!isOpen)}
            templateId={templateId}
          />
        )}
        <div className="mb-2 flex  h-14 items-center border-b lg:h-[60px]">
          <p className="font-bold capitalize">Template</p>
          <Button
            variant={"outline"}
            className="ml-auto"
            onClick={() => setIsOpen(true)}
          >
            Add Template
          </Button>
        </div>

        <div className="grid grid-cols-1 gap-8 gap-x-8 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          {!isLoading && (
            <>
              {data?.map((template) => (
                <div
                  key={template.id}
                  onClick={() => {
                    setTemplateId(template.id), setIsOpen(true);
                  }}
                  className="cursor-pointer"
                >
                  <div className="flex text-lg dark:text-black">
                    {template.title}
                  </div>
                  <div
                    className="relative  rounded-lg border bg-cover bg-center p-4 dark:bg-cover dark:bg-center"
                    style={{ backgroundImage: `url(${WhatsAppBg})` }}
                  >
                    <div className="flex items-center">
                      <div
                        className={`speech-bubble-left relative my-1 mr-auto flex flex-col whitespace-pre-line rounded-lg rounded-t-xl  rounded-br-xl rounded-tl-none border  bg-white  p-3 text-sm shadow-md dark:text-black`}
                      >
                        {template.message}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {isLoading && (
            <>
              {Array.from({ length: 6 }).map((_, index) => (
                <div key={index}>
                  <div className="flex flex-col space-y-3">
                    <Skeleton className="h-4 w-[200px]" />
                    <Skeleton className="h-[125px] rounded-xl" />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TemplateView;
