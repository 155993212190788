import { UploadIcon } from "lucide-react";
import { useState } from "react";

const Reminder = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    // Check if a file is selected
    if (file) {
      // Check if the selected file is an Excel file
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        setSelectedFile(file);
      } else {
        alert("Please upload only Excel files (xlsx or xls).");
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const downloadExcelTemplate = () => {
    // Create a blob with Excel template data
    const excelTemplate = new Blob(["Your Excel Template Data"], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary anchor element
    const a = document.createElement("a");
    const url = URL.createObjectURL(excelTemplate);

    // Set the anchor's attributes for downloading
    a.href = url;
    a.download = "excel_template.xlsx";

    // Append the anchor to the document and trigger a click event
    document.body.appendChild(a);
    a.click();

    // Remove the anchor from the document
    document.body.removeChild(a);

    // Revoke the URL to free up resources
    URL.revokeObjectURL(url);
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
  };
  return (
    <>
      <div className=" p-4 text-black">
        <div className=" w-full rounded-lg border p-4 shadow-md dark:bg-gray-800 dark:text-white">
          <div className="text-center text-lg font-bold">Reminders</div>
          <div
            className="mt-4 flex h-80 flex-col items-center justify-center rounded-lg border-4 border-dotted border-gray-300 text-lg text-gray-500 dark:border-gray-500 dark:text-gray-400"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <UploadIcon size={50} />
            <div>Drag n Drop</div>
          </div>
          <div className="flex flex-col items-end ">
            <div className="mt-4 grid grid-cols-2 gap-2">
              <div>
                <button
                  onClick={downloadExcelTemplate}
                  className="rounded bg-black px-4 py-2 text-white dark:bg-gray-500"
                >
                  Download Excel Template
                </button>
              </div>
              <input
                type="file"
                accept=".xls, .xlsx"
                onChange={handleFileChange}
                className="hidden"
                id="fileInput"
              />
              <label
                htmlFor="fileInput"
                className="cursor-pointer rounded bg-black px-4 py-2 text-white dark:bg-gray-500"
              >
                Browse Files
              </label>
            </div>
            <div className="mt-4 flex w-full justify-end rounded-md ">
              {selectedFile && (
                <div className=" flex items-center rounded-md border shadow-md dark:bg-slate-500">
                  <div className=" px-2 py-1 ">{selectedFile.name}</div>
                  <button
                    onClick={removeSelectedFile}
                    className="rounded px-2 py-1"
                  >
                    X
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 h-80  w-full rounded-lg border p-4 shadow-md dark:bg-gray-800 dark:text-white">
          reminder in progress
        </div>
      </div>
    </>
  );
};

export default Reminder;
