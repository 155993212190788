import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TemplateState {
  id?: number;
  title?: string;
  message?: string;
}

const initialState: TemplateState | null = {};

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setTemplate: (state, action: PayloadAction<TemplateState | null>) => {
      if (action.payload == null) return initialState;
      return { ...state, ...action.payload };
    },
  },
});

export const { setTemplate } = templateSlice.actions;

export default templateSlice.reducer;
