// Imports
import {
  AddressEndPoints,
  AuthEndPoints,
  CompanyEndPoints,
  TaxEndPoints,
} from "@/api/endpoints";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import {
  Avatar,
  AvatarFallback,
  AvatarImage
} from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Loader } from "@/components/ui/loader";
import { setAccessToken, setRefreshToken } from "@/redux/services/authSlice";
import { RootState } from "@/redux/store/store";
import axiosApi from "@/utils/axiosInterceptor";
import { formatMobileNumber } from "@/utils/utils";
import {
  ErrorMessage,
  Field,
  Form,
  Formik
} from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import useSWR from "swr";
import * as Yup from "yup";

// Component
const ProfileView = () => {

  // Variables
  const userdetails = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // Validation Schemas
  const profileSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required")
  });

  const companySchema = Yup.object().shape({
    name: Yup.string().required("Company name is required"),
    website: Yup.string().required("Website is required")
      .matches(/^((https?:\/\/)?(www\.)?|(www\.))(?=.+\..{2,})[^\s]+$/, "Enter a valid website URL"),
    email: Yup.string().email("Invalid email address").required("Company email is required"),
    staff_name: Yup.string().required("Staff name is required"),
    staff_mobile_number: Yup.string().matches(/^91[0-9]{10}$/, "Invalid mobile number format").required("Staff mobile number is required"),
  });

  const addressSchema = Yup.object().shape({
    address: Yup.string().required("Address Line 1 is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    pincode: Yup.string().required("Pincode is required"),
    country: Yup.string().required("Country is required")
  });

  const taxSchema = Yup.object().shape({
    tax_type: Yup.string().required("Tax type is required"),
    tax_id: Yup.string().required("Tax ID is required"),
  });

  // SWR hooks for fetching company data, address, and tax information
  const { data: companyData, mutate: mutateCompanyData } = useSWR(
    `${import.meta.env.VITE_SERVER_URL + CompanyEndPoints.GET_BUILDER_COMPANY_DATA}`,
    async (url) => {
      const response = await axiosApi.get(url);
      return response.data.success;
    },
  );
  console.log("checj", companyData)
  const { data: companyAddress, mutate: mutateCompanyAddress } = useSWR(
    `${import.meta.env.VITE_SERVER_URL + AddressEndPoints.GET_COMPANY_ADDRESS}`,
    async (url) => {
      const response = await axiosApi.get(url);
      return response.data.success;
    },
  );

  const { data: companyTax, mutate: mutateCompanyTax } = useSWR(
    `${import.meta.env.VITE_SERVER_URL + TaxEndPoints.GET_COMPANY_TAX}`,
    async (url) => {
      const response = await axiosApi.get(url);
      return response.data.success;
    },
  );

  // Logout handler
  const handleLogout = async () => {
    try {
      setLoading(true);
      const response = await axiosApi.get(
        `${import.meta.env.VITE_SERVER_URL}${AuthEndPoints.LOGOUT}`,
      );
      if (response.data.status === 200) {
        dispatch(setAccessToken(null));
        dispatch(setRefreshToken(null));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Internal error");
    }
  };

  // Submit handlers for profile, company, address, and tax forms
  const handleSubmitProfile = async (values, method, setSubmitting) => {
    try {
      setSubmitting(true);
      let response;
      if (method === "POST") {
        response = await axiosApi.post(
          `${import.meta.env.VITE_SERVER_URL}/add/`,
          values,
        );
      } else if (method === "PUT") {
        response = await axiosApi.put(`${import.meta.env.VITE_SERVER_URL}/update/`, values);
      }
      setSubmitting(false);
      toast.success(response.data.message);
    } catch (e) {
      setSubmitting(false);
    }
  };

  const handleSubmitCompany = async (values, method, setSubmitting) => {
    try {
      setSubmitting(true);
      let response;
      if (method === "POST") {
        response = await axiosApi.post(
          `${import.meta.env.VITE_SERVER_URL}${CompanyEndPoints.POST_BUILDER_COMPANY_DATA}`,
          values,
        );
      } else if (method === "PUT") {
        response = await axiosApi.put(`${import.meta.env.VITE_SERVER_URL}${CompanyEndPoints.PUT_BUILDER_COMPANY_DATA}`, values);
      }
      mutateCompanyData();
      setSubmitting(false);
      toast.success(response.data.message);
    } catch (e) {
      setSubmitting(false);
    }
  };

  const handleSubmitAddress = async (values, method, setSubmitting) => {
    try {
      setSubmitting(true);
      let response;
      if (method === "POST") {
        response = await axiosApi.post(
          `${import.meta.env.VITE_SERVER_URL}${AddressEndPoints.POST_COMPANY_ADDRESS}`,
          values,
        );
      } else if (method === "PUT") {
        response = await axiosApi.put(`${import.meta.env.VITE_SERVER_URL}${AddressEndPoints.PUT_COMPANY_ADDRESS}`, values);
      }
      mutateCompanyAddress();
      setSubmitting(false);
      toast.success(response.data.message);
    } catch (e) {
      setSubmitting(false);
    }
  };

  const handleSubmitTax = async (values, method, setSubmitting) => {
    try {
      setSubmitting(true);
      let response;
      if (method === "POST") {
        response = await axiosApi.post(
          `${import.meta.env.VITE_SERVER_URL}${TaxEndPoints.POST_COMPANY_TAX}`,
          values,
        );
      } else if (method === "PUT") {
        response = await axiosApi.put(`${import.meta.env.VITE_SERVER_URL}${TaxEndPoints.PUT_COMPANY_TAX}`, values);
      }
      mutateCompanyTax();
      setSubmitting(false);
      toast.success(response.data.message);
    } catch (e) {
      setSubmitting(false);
    }
  };
  return (
    <>
      {" "}
      <div className="flex h-14 items-center justify-between border-b px-4 lg:h-[60px] lg:px-6">
        <p className="text-lg leading-6 font-medium  capitalize">Profile</p>
        <Button variant="secondary" disabled={loading} onClick={() => handleLogout()}>{!loading && "Logout"}
          {loading && <Loader size={20} colors=""></Loader>}</Button>
      </div>

      <div className="">

        <div className=" ">
          <Accordion className="border-t border-gray-200 " collapsible type="single">
            <AccordionItem value="profile" >
              <div className="bg-gray-50 px-4 py-5 grid grid-cols-3 " >
                <div className="text-sm font-medium text-gray-500">Profile</div>
                <div className="mt-1 text-sm text-gray-900 col-span-2 lg:col-span-1 md:col-span-1">
                  <div className="flex items-center">
                    <Avatar>
                      <AvatarImage alt="Profile image" src="/placeholder.svg?height=40&width=40" />
                      <AvatarFallback>AI</AvatarFallback>
                    </Avatar>
                    <div className="ml-4">
                      <div className="font-medium">{userdetails?.first_name} {userdetails?.last_name}</div>
                      <div className="text-sm text-gray-500">{userdetails?.email}</div>
                      <div className="text-sm text-gray-500">{formatMobileNumber(userdetails?.mobile_number)}</div>
                    </div>
                  </div>
                </div>
                <AccordionTrigger show={false} disabled>
                  Edit
                </AccordionTrigger>
              </div>
              <AccordionContent>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <div className="text-sm font-medium text-gray-500">Edit Profile</div>
                  <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Formik
                      initialValues={{
                        name: userdetails?.first_name + " " + userdetails?.last_name,
                        email: userdetails?.email,
                        phone: formatMobileNumber(userdetails?.mobile_number),
                      }}
                      validationSchema={profileSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        // Submit logic
                        const editedData = Object.keys(values).reduce((acc, key) => {
                          if (userdetails[key] !== values[key]) {
                            acc[key] = values[key];
                          }
                          return acc;
                        }, {});
                        handleSubmitProfile(
                          userdetails ? editedData : values,
                          userdetails ? "PUT" : "POST",
                          setSubmitting,
                        );
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="name">Name</Label>
                              <Field name="name" as={Input} />
                              <ErrorMessage name="name" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="email">Email</Label>
                              <Field name="email" type="email" as={Input} />
                              <ErrorMessage name="email" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="phone">Phone</Label>
                              <Field name="phone" as={Input} />
                              <ErrorMessage name="phone" component="div" className="text-red-600" />
                            </div>
                          </div>
                          <div className="mt-6 flex justify-end">
                            <Button className="mr-4" variant="secondary" type="button">
                              Cancel
                            </Button>
                            <Button type="submit" disabled={isSubmitting}>
                              {!isSubmitting && "Save"}
                              {isSubmitting && <Loader size={20} colors=""></Loader>}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="company" >
              <div className="bg-white px-4 py-5 grid grid-cols-3">
                <div className="text-sm font-medium text-gray-500">Company</div>
                <div className="mt-1 text-sm text-gray-900 col-span-2 lg:col-span-1 md:col-span-1">
                  <div className="flex items-center">
                    <Avatar>
                      <AvatarImage alt="Company logo" src="/placeholder.svg?height=40&width=40" />
                      <AvatarFallback>NU</AvatarFallback>
                    </Avatar>
                    <div className="ml-4">
                      <div className="font-medium">{companyData?.name}</div>
                      <div className="text-sm text-gray-500">{companyData?.email}</div>
                    </div>

                  </div>

                </div>
                <div>
                  <AccordionTrigger className="" show={false}>
                    Edit
                  </AccordionTrigger>
                </div>
              </div>
              <AccordionContent>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <div className="text-sm font-medium text-gray-500">Edit Company</div>
                  <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Formik
                      initialValues={{
                        name: companyData?.name ?? "",
                        website: companyData?.website ?? "",
                        email: companyData?.email ?? "",
                        staff_name: companyData?.staff_name ?? "",
                        staff_mobile_number: companyData?.staff_mobile_number ?? "",
                      }}
                      validationSchema={companySchema}
                      onSubmit={(values, { setSubmitting }) => {
                        // Submit logic
                        const editedData = Object.keys(values).reduce((acc, key) => {
                          if (companyData[key] !== values[key]) {
                            acc[key] = values[key];
                          }
                          return acc;
                        }, {});
                        handleSubmitCompany(
                          companyData.length == 0 ? values : editedData,
                          companyData.length == 0 ? "POST" : "PUT",
                          setSubmitting,
                        );

                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div className="grid grid-cols-6 gap-6">
                            <p>{JSON.stringify(companyData, null, 2)}</p>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="name">Company Name</Label>
                              <Field name="name" as={Input} default={companyData?.name} />
                              <ErrorMessage name="name" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="email">Company Email</Label>
                              <Field name="email" type="email" as={Input} />
                              <ErrorMessage name="email" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="website">Company Website</Label>
                              <Field name="website" as={Input} />
                              <ErrorMessage name="website" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="staff_name">Staff Name</Label>
                              <Field name="staff_name" as={Input} />
                              <ErrorMessage name="staff_name" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="staff_mobile_number">Staff Number</Label>
                              <Field name="staff_mobile_number" as={Input} />
                              <ErrorMessage name="staff_mobile_number" component="div" className="text-red-600" />
                            </div>
                          </div>
                          <div className="mt-6 flex justify-end">
                            <Button className="mr-4" variant="secondary" type="button">
                              Cancel
                            </Button>
                            <Button type="submit" disabled={isSubmitting}>
                              {!isSubmitting && "Save"}
                              {isSubmitting && <Loader size={20} colors=""></Loader>}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="address">
              <div className="bg-gray-50 px-4 py-5 grid grid-cols-3" >
                <div className="text-sm font-medium text-gray-500">Address</div>
                <div className="mt-1 text-sm text-gray-900 col-span-2 lg:col-span-1 md:col-span-1 ">
                  <div className="font-medium">{companyAddress?.address}</div>
                  <div className="text-sm text-gray-500">{companyAddress?.city} ,
                    {companyAddress?.state} ,
                    {companyAddress?.pincode} ,
                    {companyAddress?.country}</div>
                </div>
                <AccordionTrigger show={false}>
                  Edit
                </AccordionTrigger>
              </div>
              <AccordionContent>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <div className="text-sm font-medium text-gray-500">Edit Address</div>
                  <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Formik
                      initialValues={{
                        address: companyAddress?.address,
                        city: companyAddress?.city,
                        state: companyAddress?.state,
                        pincode: companyAddress?.pincode,
                        country: companyAddress?.country,
                      }}
                      validationSchema={addressSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        // Submit logic
                        const editedData = Object.keys(values).reduce((acc, key) => {
                          if (companyAddress[key] !== values[key]) {
                            acc[key] = values[key];
                          }
                          return acc;
                        }, {});
                        handleSubmitAddress(
                          companyAddress.length == 0 ? values : editedData,
                          companyAddress.length == 0 ? "POST" : "PUT",
                          setSubmitting,
                        );
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                              <Label htmlFor="address">Address Line 1</Label>
                              <Field name="address" as={Input} />
                              <ErrorMessage name="address" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="city">City</Label>
                              <Field name="city" as={Input} />
                              <ErrorMessage name="city" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="state">State</Label>
                              <Field name="state" as={Input} />
                              <ErrorMessage name="state" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="pincode">Pincode</Label>
                              <Field name="pincode" as={Input} />
                              <ErrorMessage name="pincode" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <Label htmlFor="country">Country</Label>
                              <Field name="country" as={Input} />
                              <ErrorMessage name="country" component="div" className="text-red-600" />
                            </div>
                          </div>
                          <div className="mt-6 flex justify-end">
                            <Button className="mr-4" variant="secondary" type="button">
                              Cancel
                            </Button>

                            <Button type="submit" disabled={isSubmitting}>
                              {!isSubmitting && "Save"}
                              {isSubmitting && <Loader size={20} colors=""></Loader>}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="tax">
              <div className="bg-white px-4 py-5 grid grid-cols-3">
                <div className="text-sm font-medium text-gray-500">Tax</div>
                <div className="mt-1 text-sm text-gray-900 col-span-2 lg:col-span-1 md:col-span-1">
                  <div className="font-medium">{companyTax?.tax_type} - {companyTax?.tax_id}</div>

                </div>
                <AccordionTrigger show={false}>
                  Edit
                </AccordionTrigger>
              </div>
              <AccordionContent>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <div className="text-sm font-medium text-gray-500">Edit Tax</div>
                  <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <Formik
                      initialValues={{
                        tax_type: companyTax?.tax_type ?? "",
                        tax_id: companyTax?.tax_id ?? "",
                      }}
                      validationSchema={taxSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        // Submit logic
                        const editedData = Object.keys(values).reduce((acc, key) => {
                          if (companyTax[key] !== values[key]) {
                            acc[key] = values[key];
                          }
                          return acc;
                        }, {});
                        handleSubmitTax(
                          companyTax.length == 0 ? values : editedData,
                          companyTax.length == 0 ? "POST" : "PUT",
                          setSubmitting,
                        );
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                              <Label htmlFor="tax_id">Tax ID</Label>
                              <Field name="tax_id" as={Input} />
                              <ErrorMessage name="tax_id" component="div" className="text-red-600" />
                            </div>
                            <div className="col-span-6">
                              <Label htmlFor="tax_type">Tax Type</Label>
                              <Field name="tax_type" as={Input} />
                              <ErrorMessage name="tax_type" component="div" className="text-red-600" />
                            </div>
                          </div>
                          <div className="mt-6 flex justify-end">
                            <Button className="mr-4" variant="secondary" type="button">
                              Cancel
                            </Button>
                            <Button type="submit" disabled={isSubmitting}>
                              {!isSubmitting && "Save"}
                              {isSubmitting && <Loader size={20} colors=""></Loader>}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ProfileView;
