import { cloneElement } from "react";

const CountCard = ({ title, count, icon, iconColor }) => {
  return (
    <div className="rounded-xl border bg-white p-6 shadow-lg dark:bg-gray-800">
      <div className="flex items-center justify-between">
        <div className="mr-4 rounded-md  p-4 text-white">
          {/* Render the provided icon component */}
          {icon &&
            cloneElement(icon, {
              className: "h-8 w-8",
              style: { color: iconColor },
            })}
        </div>
        <div>
          <p className="text-lg  capitalize dark:text-white">{title}</p>
          <p className="text-right text-2xl  dark:text-white">{count}</p>
        </div>
      </div>
    </div>
  );
};

export default CountCard;
