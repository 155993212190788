// LeadModal.tsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@/components/ui/dialog";
import { WhatsappEndPoints } from "@/api/endpoints";
import axiosApi from "@/utils/axiosInterceptor";

interface LeadModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}
interface ILeadData {
  feedback: string;
  user: {
    id: number;
    name: string;
    mobile_number: string;
  };
  follow_up_required: string;
  intrested_in: string;
  lead_generated_date: string;
  lead_generated_time: string;
  level_of_intrest: number;
  likely_to_upscale: number;
  name_of_user: string;
  pickup_location: string;
  rating: number;
  sales_status: string;
}

const LeadModal: React.FC<LeadModalProps> = ({ isOpen, onClose, userId }) => {
  const [leadData, setLeadData] = useState<ILeadData>();

  useEffect(() => {
    handleChatData();
  }, [userId]);

  const handleChatData = async () => {
    try {
      const response = await axiosApi.post(
        `${import.meta.env.VITE_SERVER_URL}${WhatsappEndPoints.USERLEAD}`,
        {
          user_id: userId,
        },
      );

      setLeadData(response.data.success);
    } catch (e) {
      //   handleApiError(e);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="min-w-fit sm:max-w-[425px]">
        <DialogTitle>Leads</DialogTitle>
        <div className="flex items-center justify-center">
          {leadData?.lead_generated_date ? (
            <div>
              <div className="grid grid-cols-2">
                <div className="mt-4">
                  <DialogDescription className="rounded-md  p-2 text-left">
                    Name :{" "}
                    <span className="font-bold">{leadData.name_of_user}</span>
                  </DialogDescription>
                </div>
                <div className="mt-4">
                  <DialogDescription className="rounded-md  p-2 text-left">
                    Mobile No :{" "}
                    <span className="font-bold">
                      {leadData.user.mobile_number}
                    </span>
                  </DialogDescription>
                </div>
                <div className="mt-4">
                  <DialogDescription className="rounded-md  p-2 text-left">
                    Sale Status :{" "}
                    <span className="font-bold">{leadData.sales_status}</span>
                  </DialogDescription>
                </div>
                <div className="mt-4">
                  <DialogDescription className="rounded-md p-2 text-left">
                    Interested In :{" "}
                    <span className="font-bold">{leadData.intrested_in}</span>
                  </DialogDescription>
                </div>{" "}
                <div className="mt-4">
                  <DialogDescription className="rounded-md p-2 text-left">
                    Likely to Upscale :{" "}
                    <span className="font-bold">
                      {leadData.likely_to_upscale}
                    </span>
                  </DialogDescription>
                </div>
                <div className="mt-4">
                  <DialogDescription className="rounded-md p-2 text-left">
                    Interest Level :{" "}
                    <span className="font-bold">
                      {leadData.level_of_intrest}
                    </span>
                  </DialogDescription>
                </div>
                <div className="mt-4">
                  <DialogDescription className="rounded-md p-2 text-left">
                    Rating :{" "}
                    <span className="font-bold">{leadData.rating}</span>
                  </DialogDescription>
                </div>
                <div className="mt-4">
                  <DialogDescription className="rounded-md p-2 text-left">
                    Pickup Location :{" "}
                    <span className="font-bold">
                      {leadData.pickup_location}
                    </span>
                  </DialogDescription>
                </div>
                <div className="mt-4">
                  <DialogDescription className="rounded-md p-2 text-left">
                    Feedback :{" "}
                    <span className="font-bold">{leadData.feedback}</span>
                  </DialogDescription>
                </div>
                <div className="mt-4">
                  <DialogDescription className="rounded-md  p-2 text-left">
                    Follow Up : {leadData.follow_up_required}
                  </DialogDescription>
                </div>
              </div>
              <div className="text-center">
                {leadData.lead_generated_date}
                {" | "}
                {leadData.lead_generated_time}
              </div>
            </div>
          ) : (
            <div className="mt-4 flex w-72  text-center text-gray-500">
              <p>No leads available at the moment</p>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LeadModal;
