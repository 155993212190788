import { useState } from "react";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

import { GitHubLogoIcon } from "@radix-ui/react-icons";

import { Menu } from "lucide-react";

import { ModeToggle } from "@/components/mode-toggle";

import { buttonVariants } from "@/components/ui/button";
import { Logo, NexByDark, NexByLight } from "@/assets";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store/store";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface RouteProps {
  href: string;
  label: string;
}

const routeList: RouteProps[] = [
  {
    href: "#features",
    label: "Features",
  },
  {
    href: "#testimonials",
    label: "Testimonials",
  },
  {
    href: "#team",
    label: "Team",
  },
  {
    href: "#faq",
    label: "FAQ",
  },
];

export const Navbar = () => {
  const { currentTheme } = useSelector((state: RootState) => state.theme);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <header className="container sticky top-4 z-40 rounded-full border-[1px] bg-white bg-opacity-40 bg-clip-padding backdrop-blur-sm backdrop-filter dark:border-gray-700 dark:bg-background">
      <div className="">
        <div className="flex h-14  items-center justify-between">
          <div className="font-bold">
            <div className="flex items-center ">
              {/* <img className="h-6" src={Logo} alt="logog" /> */}
              <img
                className="h-6"
                src={currentTheme === "light" ? NexByDark : NexByLight}
                alt="logo"
              />
            </div>
          </div>

          {/* mobile */}
          <div className="flex md:hidden">
            <ModeToggle />

            <Sheet open={isOpen} onOpenChange={setIsOpen}>
              <SheetTrigger className="">
                <Menu
                  className="flex h-5 w-5 md:hidden"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="sr-only">Menu Icon</span>
                </Menu>
              </SheetTrigger>

              <SheetContent side={"left"}>
                <SheetHeader>
                  <SheetTitle className="text-xl font-bold">
                    <div className="flex items-center ">
                      <img className="h-6" src={Logo} alt="logog" />
                      <img className="h-6" src={NexByDark} alt="logo" />
                    </div>
                  </SheetTitle>
                </SheetHeader>
                <nav className="mt-4 flex flex-col items-center justify-center gap-2">
                  {routeList.map(({ href, label }: RouteProps) => (
                    <a
                      key={label}
                      href={href}
                      onClick={() => setIsOpen(false)}
                      className={buttonVariants({ variant: "ghost" })}
                    >
                      {label}
                    </a>
                  ))}
                  <Button
                    variant={"outline"}
                    className="border-none"
                    onClick={() => navigate("auth/")}
                  >
                    Log in
                  </Button>
                  <Button
                    variant={"outline"}
                    className="border-none"
                    onClick={() => navigate("auth/register")}
                  >
                    Sign up
                  </Button>
                </nav>
              </SheetContent>
            </Sheet>
          </div>

          {/* desktop */}

          <nav className="hidden md:flex">
            {routeList.map((route: RouteProps, i) => (
              <a
                href={route.href}
                key={i}
                className={`text-[17px] ${buttonVariants({
                  variant: "ghost",
                })}`}
              >
                {route.label}
              </a>
            ))}
          </nav>

          <div className="hidden  md:flex">
            <Button
              variant={"outline"}
              className="mr-2 border-none"
              onClick={() => navigate("auth/")}
            >
              Log in
            </Button>
            <Button
              variant={"outline"}
              className="border-none"
              onClick={() => navigate("auth/register")}
            >
              Sign up
            </Button>
            <ModeToggle />
          </div>
        </div>
      </div>
    </header>
  );
};
