"use client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface FAQProps {
  question: string;
  answer: string;
  value: string;
}

const FAQList: FAQProps[] = [
  {
    question: "Is the Service free?",
    answer:
      "No, the service is not free. but you have 1 project creation free.",
    value: "item-1",
  },
  {
    question: "How do I get started?",
    answer:
      "To get started, you need to sign up for an account and create a company profile. Once you have done that, you can start using the service.",
    value: "item-2",
  },
  {
    question: "What are the benefits of using the service?",
    answer:
      "The benefits of using the service include increased visibility, better customer engagement, and improved hiring processes.",
    value: "item-3",
  },
  {
    question: "Does the service have a demo?",
    answer: "Demo is not available yet.",
    value: "item-4",
  },
  {
    question: "Is the service available in multiple languages?",
    answer: "No, the service is only available in English at the moment.",
    value: "item-5",
  },
];

export const FAQ = () => {
  return (
    <section id="faq" className="container py-24 sm:py-32">
      <h2 className="mb-4 text-3xl font-bold md:text-4xl">
        Frequently Asked{" "}
        <span className="bg-gradient-to-b from-primary/60 to-primary bg-clip-text text-transparent">
          Questions
        </span>
      </h2>

      <Accordion type="single" collapsible className="AccordionRoot w-full">
        {FAQList.map(({ question, answer, value }: FAQProps) => (
          <AccordionItem key={value} value={value}>
            <AccordionTrigger className="text-left">
              {question}
            </AccordionTrigger>

            <AccordionContent>{answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>

      <h3 className="mt-4 font-medium">
        Still have questions?{" "}
        <a
          href="tel:+1234567890"
          className="border-primary text-primary transition-all hover:border-b-2"
        >
          Contact us
        </a>
      </h3>
    </section>
  );
};
