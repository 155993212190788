// components/CustomDropdown.tsx
import React, { useEffect, useState } from "react";

interface CustomDropdownProps {
  title?: string;
  options: any[] | undefined;
  displayKey: string;
  getValue: string;
  getDataCallback: (val: any) => void;
  editOptions: any[];
  enableSearch?: boolean;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  title,
  displayKey,
  getValue,
  getDataCallback,
  editOptions,
  enableSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    editOptions.length > 0 ? editOptions : [],
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<any[] | undefined>(
    options,
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleOptionToggle = (option: any) => {
    if (selectedOptions.some((selectedOption) => selectedOption === option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
      getDataCallback(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
      getDataCallback([...selectedOptions, option]);
    }
  };

  useEffect(() => {
    setFilteredOptions(
      options?.filter((option) => {
        const displayValue =
          typeof option === "object" ? option[displayKey] : option;
        return displayValue.toLowerCase().includes(searchValue.toLowerCase());
      }),
    );
  }, [searchValue, options, displayKey]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdown = document.getElementById("dropdownSearch");
      const button = document.getElementById("dropdownSearchButton");

      if (
        dropdown &&
        !dropdown.contains(event.target as Node) &&
        button &&
        !button.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <button
        id="dropdownSearchButton"
        data-dropdown-toggle="dropdownSearch"
        data-dropdown-placement="bottom"
        type="button"
        onClick={toggleDropdown}
        className="inline-flex h-9 items-center justify-center whitespace-nowrap rounded-md border border-input bg-background text-sm font-medium shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
      >
        <span className="flex flex-row items-center px-2">
          {title ? title : "dropdown filter"}
          <svg
            className="ms-3 h-2.5 w-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </span>
      </button>
      {isOpen && (
        <div
          id="dropdownSearch"
          className="absolute left-0 top-full z-10 block w-60 rounded-lg bg-white shadow dark:bg-gray-700"
        >
          {enableSearch && (
            <div className="p-3">
              <label htmlFor="input-group-search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="rtl:inset-r-0 pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                  <svg
                    className="h-4 w-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="input-group-search"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 ps-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="Search ..."
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          )}
          <ul
            className="flex flex-col overflow-y-auto px-3 pb-3 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownSearchButton"
          >
            {filteredOptions?.map((option, index) => (
              <li key={index}>
                <div className="flex items-center rounded ps-2 hover:bg-gray-100 dark:hover:bg-gray-600">
                  <input
                    id={`checkbox-item-${
                      typeof option === "object" ? option[displayKey] : option
                    }`}
                    type="checkbox"
                    value=""
                    className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                    checked={selectedOptions.some(
                      (selectedOption) =>
                        selectedOption ===
                        (typeof option === "object"
                          ? option[getValue]
                          : option),
                    )}
                    onChange={() =>
                      handleOptionToggle(
                        typeof option === "object" ? option[getValue] : option,
                      )
                    }
                  />
                  <label
                    htmlFor={`checkbox-item-${
                      typeof option === "object" ? option[displayKey] : option
                    }`}
                    className="ms-2 w-full rounded py-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    {typeof option === "object" ? option[displayKey] : option}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
