import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { MedalIcon, MapIcon, PlaneIcon, GiftIcon } from "../components/Icons";

interface FeatureProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

const features: FeatureProps[] = [
  {
    icon: <MedalIcon />,
    title: "Company Overview",
    description:
      "We start by understanding your company inside and out. This includes your mission, values, and unique selling propositions.",
  },
  {
    icon: <MapIcon />,
    title: "Product/Service Details",
    description:
      "Our AI Sales Agent dives deep into the intricacies of your offerings, ensuring it's well-versed in every feature, benefit, and use case.",
  },
  {
    icon: <PlaneIcon />,
    title: "Customer Data",
    description:
      "Armed with a comprehensive understanding of your customer base, our AI Sales Agent can tailor its approach to meet the specific needs and preferences of each individual. ",
  },
  {
    icon: <GiftIcon />,
    title: "Sales Process Guidelines",
    description:
      "Whether you follow a traditional sales funnel or have a unique approach, our AI Sales Agent aligns seamlessly with your established processes",
  },
  {
    icon: <GiftIcon />,
    title: "Promotions and Offers",
    description:
      "Keep your AI Sales Agent up-to-date with the latest promotions, discounts, and special offers to capitalise on every sales opportunity",
  },
  {
    icon: <GiftIcon />,
    title: "Updates and Changes",
    description:
      "As your company evolves, so does our AI Sales Agent. Regular updates ensure it remains agile and effective in adapting to new strategies and market dynamics",
  },
];

export const HowItWorks = () => {
  return (
    <section id="howItWorks" className="container py-24 text-center sm:py-32">
      <h2 className="mb-4 text-3xl font-bold md:text-4xl ">
        How It{" "}
        <span className="bg-gradient-to-b from-primary/60 to-primary bg-clip-text text-transparent">
          Works{" "}
        </span>
        Step-by-Step Guide
      </h2>
      {/* <p className="mx-auto mb-8 mt-4 text-xl text-muted-foreground md:w-3/4">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis
        dolor pariatur sit!
      </p> */}

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {features.map(({ icon, title, description }: FeatureProps) => (
          <Card key={title} className="bg-muted/50">
            <CardHeader>
              <CardTitle className="grid place-items-center gap-4">
                {icon}
                {title}
              </CardTitle>
            </CardHeader>
            <CardContent>{description}</CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
};
