import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { Button } from "../../components/ui/button";
import { Input } from "@/components/ui/input";
import axiosApi from "@/utils/axiosInterceptor";
import { AuthEndPoints } from "@/api/endpoints";
import { Loader } from "@/components/ui/loader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NexByDark, NexByLight } from "@/assets";
interface IRegister {
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  password: string;
  confirm_password: string;
}

const Register: React.FC = () => {
  const navigate = useNavigate();

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    last_name: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    mobile_number: Yup.string().required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Must be at least 8 characters")
      .test(
        "hasSpecialChar",
        "Must include at least one special character",
        (value) => /\W/.test(value),
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
        "Must include at least one uppercase, lowercase letter, and number",
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Required"),
  });

  const handleRegistration = async (
    userDetails: IRegister,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      const response = await axiosApi.post(
        `${import.meta.env.VITE_SERVER_URL + AuthEndPoints.REGISTER}`,
        { ...userDetails },
        { withCredentials: true },
      );

      if (response.status === 201) {
        navigate("/auth/");
      }
      setSubmitting(false);
    } catch (error: unknown) {
      // Handle Axios error (network error, timeout, etc.) or server response error

      setSubmitting(false);
    }
  };
  const getRandomNumber = () => Math.floor(Math.random() * 9) + 1;

  const imageUrl = `https://nexby-document-storage.s3.ap-south-1.amazonaws.com/static-assests/${getRandomNumber()}.jpg`;
  return (
    <section className="relative h-screen flex flex-col lg:flex-row lg:max-w-none lg:px-0">
      <div className="w-full lg:w-3/5 relative hidden lg:block">
        {/* Image div taking 60% width */}
        <div className="h-full bg-muted p-10 text-white dark:border-r">
          <div className="absolute inset-0 overflow-hidden">
            <img
              src={imageUrl}
              alt="Random"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="relative z-20 flex items-start justify-start h-3/4">
            <img src={NexByLight} alt="" width={150} height={150} />
          </div>
          {/* quote to the end of the component */}
          <div className="absolute bottom-0 left-0 right-0 p-10">
            <p className="text-lg font-semibold">
              &quot;The best way to predict the future is to create it.&quot;
            </p>
            <p className="text-sm text-muted-foreground">- Peter Drucker</p>
          </div>
        </div>
      </div>
      <div className="w-full h-screen  lg:w-2/5 p-4 lg:p-8 flex flex-col justify-center items-center ">
        <div className=" mx-4 flex w-full max-w-sm flex-col justify-center md:mx-auto md:w-80">
          <div className="text-center">
            <h5 className="p-3 text-xl font-bold">Register Account</h5>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleRegistration(values, setSubmitting);
            }}
            validateOnBlur
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  className=" grid w-full max-w-md grid-cols-1 gap-4"
                >
                  {/* First Name */}
                  <div className="relative">
                    <label >
                      First Name
                    </label>
                    <Input
                      placeholder=""
                      type="text"
                      name="first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                    />
                  </div>
                  <ErrorMessage
                    name={`first_name`}
                    render={(msg) => (
                      <div className="text-xs text-red-500">{msg}</div>
                    )}
                  />

                  {/* Last Name */}
                  <div className="relative ">
                    <label >
                      Last Name
                    </label>
                    <Input
                      placeholder=" "
                      type="text"
                      name="last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                    />
                  </div>
                  <ErrorMessage
                    name={`last_name`}
                    render={(msg) => (
                      <div className="text-xs text-red-500">{msg}</div>
                    )}
                  />

                  {/* Email */}
                  <div className="relative">
                    <label >
                      Email
                    </label>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>
                  <ErrorMessage
                    name={`email`}
                    render={(msg) => (
                      <div className="text-xs text-red-500">{msg}</div>
                    )}
                  />
                  {/* Mobile Number */}
                  <Field
                    id="mobile_number"
                    component={PhoneInput}
                    class="form-control "
                    name="mobile_number"
                    country="in"
                    autoFormat
                    enableSearch
                    type="tel"
                    placeholder="Enter Mobile Number"
                    onChange={handleChange("mobile_number")}
                    onBlur={handleBlur("mobile_number")}
                    inputStyle={{ width: "100%" }}
                    containerStyle={{
                      border: "1px solid black",
                      borderRadius: "4px",
                      marginTop: "5px",
                    }}
                    buttonStyle={{ borderRight: "1px solid black" }}
                  />
                  <ErrorMessage
                    name="mobile_number"
                    component="div"
                    className="text-xs text-red-500"
                  />

                  {/* Password */}
                  <div className="relative ">
                    <label >
                      Password
                    </label>
                    <Input
                      placeholder=" "
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />

                  </div>
                  <ErrorMessage
                    name={`password`}
                    render={(msg) => (
                      <div className="text-xs text-red-500">{msg}</div>
                    )}
                  />

                  {/* Confirm Password */}
                  <div className="relative ">
                    <label >
                      Confirm Password
                    </label>
                    <Input
                      placeholder=" "
                      type="password"
                      name="confirm_password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm_password}
                    />
                  </div>
                  <ErrorMessage
                    name={`confirm_password`}
                    render={(msg) => (
                      <div className="text-xs text-red-500">{msg}</div>
                    )}
                  />

                  <Button type="submit" disabled={isSubmitting}>
                    {!isSubmitting && "Register"}
                    {isSubmitting && <Loader size={20} colors=""></Loader>}
                  </Button>
                </form>
              );
            }}
          </Formik>
          <div className="mt-4 pt-3 text-center">
            <p className="">
              Already have an account?{" "}
              <Link to="/auth/" className="text-blue-500">
                Login
              </Link>{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
