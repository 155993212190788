import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import React, { useState } from 'react';

interface Schedule {
  start: string;
  end: string;
}

interface WeekSchedule {
  [day: string]: Schedule;
}

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

const Scheduler: React.FC = () => {
  const [schedule, setSchedule] = useState<WeekSchedule>(() =>
    daysOfWeek.reduce((acc, day) => {
      const storedSchedule = localStorage.getItem(day);
      acc[day] = storedSchedule ? JSON.parse(storedSchedule) : { start: '', end: '' };
      return acc;
    }, {} as WeekSchedule)
  );

  const handleTimeChange = (day: string, field: 'start' | 'end', value: string) => {
    if (/^([01]\d|2[0-3]):[0-5]\d$/.test(value)) {
      setSchedule(prev => {
        const updatedDaySchedule = { ...prev[day], [field]: value };
        localStorage.setItem(day, JSON.stringify(updatedDaySchedule));
        return {
          ...prev,
          [day]: updatedDaySchedule
        };
      });
    } else {
      console.log("Invalid time format");
    }
  };

  const handleSubmit = () => {
    const invalidDays = daysOfWeek.filter(day => {
      const { start, end } = schedule[day];
      return start && end && start >= end;
    });

    if (invalidDays.length) {
      alert(`End time must be after start time for: ${invalidDays.join(', ')}`);
    } else {
      alert(JSON.stringify(schedule, null, 2));
    }
  };

  return (
    <div className="scheduler">
      <div className="header flex justify-between p-2 border-b">
        <div className="text-lg font-semibold">Week</div>
        <div className="flex">
          <div className="text-lg font-semibold mr-4">Start Time</div>
          <div className="text-lg font-semibold mr-4">End Time</div>
        </div>
      </div>
      <div className="schedule-inputs mb-4">
        {daysOfWeek.map(day => (
          <div key={day} className="day-schedule flex justify-between items-center p-2 rounded">
            <h3 className="text-lg font-semibold">{day}</h3>
            <div className="flex">
              <Input
                type="text"
                value={schedule[day].start}
                pattern="^([01]\d|2[0-3]):[0-5]\d$"
                onChange={e => handleTimeChange(day, 'start', e.target.value)}
                className="mr-4"
                placeholder="HH:MM"
              />
              <Input
                type="text"
                value={schedule[day].end}
                pattern="^([01]\d|2[0-3]):[0-5]\d$"
                onChange={e => handleTimeChange(day, 'end', e.target.value)}
                placeholder="HH:MM"
              />
            </div>
          </div>
        ))}
      </div>
      <Button onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default Scheduler;
