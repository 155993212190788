import type { ColumnDef } from "@tanstack/react-table";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState, HTMLProps, useEffect, useRef } from "react";
import { Loader } from "../ui/loader";
import UseDebouncing from "../debouncing";
import CButton from "../ui/cbutton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { DataTableToolbar } from "./data-table-toolbar";
import { DataTablePagination } from "./data-table-pagination";
import { PageDetails } from "@/types/propsType";

interface props<T extends object> {
  //- An array of data items of type T to be displayed in the table.
  data: T[];

  //- An array of column definitions (of type ColumnDef<T>) for the table.
  columns: ColumnDef<T>[];

  //- A flag indicating whether pagination should be shown.

  //- A function to apply a global filter to the table data based on a search term.
  globalFilter?: (searchTerm: string) => void;
  searchPlaceholder?: string;
  dateFn?: (dateValue: string) => void;
  statusFn?: ([string]) => void;
  //- A function for adding a new data item.
  addData?: () => void;
  exportData?: () => void;
  buttonName?: NonNullable<props<T>["addData"]> extends () => void
    ? string
    : never;
  uploadData?: () => void;
  handleExport?: () => void;
  //- A function to handle pagination clicks, providing the page number and a value.
  handlePaginationClick?: (pageNumber: number) => void;
  handlePageSize?: (pageSize: number) => void;
  //- The total number of pages for pagination.

  pageDetails?: PageDetails;
  //-title of the table
  title?: string;
  //-filtering of data
  filterCallBack?: (value: any) => void;
  filterData?: { data: any[] | undefined; filterValue: any; filterName: any };
  filterEditData?: any[];
  //- A function to provide icons for the table.
  loader?: boolean;
  disabledDate?: {
    after: any;
    before: any;
  };
}

const CTable = <T extends object>({
  data,
  columns,
  globalFilter,
  addData,
  exportData,
  uploadData,
  handleExport,
  handlePaginationClick,
  handlePageSize,
  pageDetails,
  searchPlaceholder,
  disabledDate,
  dateFn,
  statusFn,
  filterCallBack,
  filterData,
  filterEditData,
  loader,
}: props<T>) => {
  // Define and set initial states using the useState hook
  // facetet filter
  // Current page for pagination
  const [sorting, setSorting] = useState<SortingState>([]); // Sorting state for columns
  const [searchValue, setSearchValue] = useState(""); // Filter value for global filtering

  // Apply debouncing to the filter value
  const debounceValue = UseDebouncing<string>(searchValue, 1000);
  globalFilter ? globalFilter(searchValue) : null;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: searchValue,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearchValue,
  });

  return (
    <div className="mb-10 space-y-4">
      {searchPlaceholder && (
        <DataTableToolbar
          table={table}
          onExport={handleExport}
          searchFilter={searchValue}
          searchFilterFn={setSearchValue}
          searchPlaceholder={searchPlaceholder}
          dateFn={dateFn}
          statusFn={statusFn}
          disabledDate={disabledDate}
        />
      )}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {pageDetails && handlePaginationClick && handlePageSize && (
        <DataTablePagination
          table={table}
          pageDetails={pageDetails}
          handlePage={(pageNumber) => handlePaginationClick(pageNumber)}
          handleSize={(pageSize) => handlePageSize(pageSize)}
        />
      )}
    </div>
  );
};

export function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " checkbox checkbox-primary cursor-pointer"}
      {...rest}
    />
  );
}
export default CTable;
