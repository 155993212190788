const Staff = () => {
  return (
    <>
      <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
        <p className="font-bold capitalize">Staff</p>
      </div>
      <div className="p-4">
        <div>welcome to Staff</div>
      </div>
    </>
  );
};

export default Staff;
