import {
  BotIcon,
  Building2,
  CalendarClockIcon,
  LeafIcon,
  MessageSquareCode,
  MessageSquareDot,
  MessageSquareMore,
  MessageSquareQuote,
  Smartphone,
  User2Icon,
} from "lucide-react";
import CountCard from "./count-cards";
import axiosApi from "@/utils/axiosInterceptor";
import useSWR from "swr";
import { Skeleton } from "@/components/ui/skeleton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store/store";

const Dashboard = () => {
  const { currentTheme } = useSelector((state: RootState) => state.theme);

  async function getdata() {
    try {
      const response = await axiosApi.get(
        `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/profile/dashbord/analysis/`,
      );
      console.log("data", response.data);
      return response.data.success;
    } catch (e) {
      console.log(e);
    }
  }

  const { data, isLoading } = useSWR(
    "/api/v1/builder/profile/dashbord/analysis/",
    getdata,
  );

  return (
    <>
      <div className="flex h-14 items-center justify-between border-b px-4 lg:h-[60px] lg:px-6">
        <p className="font-bold capitalize">Dashboard</p>
      </div>
      <div className="p-4">
        {isLoading && (
          <div className="mb-5 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {Array.from({ length: 10 }, (_, index) => (
              <Skeleton key={index} className="h-[105px] rounded-xl" />
            ))}
          </div>
        )}

        {data && (
          <div>
            <div className="my-4 flex h-14 justify-between border-b pb-2 text-xl">
              <p>Account</p>

              <div className="flex rounded-xl ">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex items-center">
                    <div className="mr-2 h-3 w-3 rounded-full bg-green-500"></div>
                    <p className="text-gray-600 dark:text-white">
                      Active session{" "}
                    </p>
                  </div>
                  <p>{data?.total_active_session}</p>
                </div>
                <p className="mx-2">|</p>
                <div className=" flex flex-col items-center justify-center">
                  <p className="text-gray-600 dark:text-white">
                    Mobile numbers
                  </p>
                  <p>{data?.total_mobile_number}</p>
                </div>
              </div>
            </div>
            <div className="mb-5 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <Link to={"/pages/projects"}>
                <CountCard
                  title={"projects"}
                  count={data?.total_projects}
                  icon={<Building2 />}
                  iconColor={currentTheme == "light" ? "black" : "white"}
                />
              </Link>
              <CountCard
                title={"Follow-up Count"}
                count={data?.total_customer_followup}
                icon={<CalendarClockIcon />}
                iconColor={currentTheme == "light" ? "black" : "white"}
              />

              <CountCard
                title={"Customers"}
                count={data?.total_customer}
                iconColor={currentTheme == "light" ? "black" : "white"}
                icon={<User2Icon />}
              />

              <CountCard
                title={"Leads"}
                count={data?.total_leads}
                iconColor={currentTheme == "light" ? "black" : "white"}
                icon={<LeafIcon />}
              />
            </div>
            <div>
              <p className="my-4 flex border-b pb-2 text-xl">Messages</p>
              <div className="mb-5 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <CountCard
                  title={"Lead reply"}
                  count={data?.total_lead_replied}
                  iconColor={currentTheme == "light" ? "black" : "white"}
                  icon={<MessageSquareMore />}
                />
                <CountCard
                  title={"Total Message Sent"}
                  count={data?.total_message_sent}
                  iconColor={currentTheme == "light" ? "black" : "white"}
                  icon={<MessageSquareCode />}
                />
                {/* <CountCard
                  title={"Message Received"}
                  count={data.total_customer}
                  iconColor={currentTheme == "light" ? "black" : "white"}
                  icon={<MessageSquareDot />}
                /> */}
                <CountCard
                  title={"AI Generated Responses"}
                  count={data?.total_ai_generated_message}
                  iconColor={currentTheme == "light" ? "black" : "white"}
                  icon={<BotIcon />}
                />
              </div>
            </div>
            <div>
              <p className="my-4 flex border-b pb-2 text-xl">Follow-Up</p>
              <div className="mb-5 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <CountCard
                  title={"Followups Message"}
                  count={data?.total_follwoup_message}
                  iconColor={currentTheme == "light" ? "black" : "white"}
                  icon={<MessageSquareQuote />}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
