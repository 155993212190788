export enum AuthEndPoints {
  REGISTER = "/api/v1/user/registration/",
  LOGIN = "/api/v1/user/authenticate/",
  VERIFYEMAIL = "/api/v1/user/registration/verify-email/",
  VERIFYMOBILE = "/api/v1/user/registration/verify-mobile-number/",
  LOGOUT = "/api/v1/user/authenticate/logout/",
}

export enum WhatsappEndPoints {
  USERDETAILS = "/api/v1/chats/retrieve-all-user-details",
  USERSUMMARY = "/api/v1/chats/retrieve-user-summary/",
  USERLEAD = "/api/v1/chats/retrieve-user-lead/",
  USERCONVERSATION = "/api/v1/chats/retrieve-user-conversation/",
  USERDATA = "/api/v1/chats/retrieve-dashboard-details/",
}

export enum ExportEndPoints {
  EXPORTLEAD = "/api/v1/chats/generate-lead-excel/",
}

export enum CompanyEndPoints {
  GET_BUILDER_COMPANY_DATA = "/api/v1/builder/company-profile/",
  POST_BUILDER_COMPANY_DATA = "/api/v1/builder/company-profile/add/",
  PUT_BUILDER_COMPANY_DATA = "/api/v1/builder/company-profile/update/",
}

export enum AddressEndPoints {
  GET_COMPANY_ADDRESS = "/api/v1/builder/company-profile/address/",
  POST_COMPANY_ADDRESS = "/api/v1/builder/company-profile/address/add/",
  PUT_COMPANY_ADDRESS = "/api/v1/builder/company-profile/address/update/",
  GET_BUILDER_ADDRESS = "/api/v1/builder/address/",
  POST_BUILDER_ADDRESS = "/api/v1/builder/address/add/",
  PUT_BUILDER_ADDRESS = "/api/v1/builder/address/update/",
}

export enum TaxEndPoints {
  GET_COMPANY_TAX = "/api/v1/builder/profile/taxation/",
  POST_COMPANY_TAX = "/api/v1/builder/profile/taxation/add/",
  PUT_COMPANY_TAX = "/api/v1/builder/profile/taxation/update/",
  GET_TAX_DATA = "/api/v1/builder/taxation/",
  POST_TAX_DATA = "/api/v1/builder/taxation/add/",
  PUT_TAX_DATA = "/api/v1/builder/taxation/update/",
}

export enum NotificationEndPoints {
  GET_NOTIFICATION = "/api/v1/builder/notification/",
  POST_NOTIFICATION = "/api/v1/builder/notification/add/",
  PUT_NOTIFICATION = "/api/v1/builder/notification/update/",
  DELETE_NOTIFICATION = "/api/v1/builder/notification/delete/",
}

export enum ProjectEndPoints {
  GET_PROJECT = "/api/v1/builder/project/",
  POSt_PROJECT = "/api/v1/builder/project/add/",
  PUT_PROJECT = "/api/v1/builder/project/update/",
}
