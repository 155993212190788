import { toast } from "sonner";

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.success("Copied to clipboard");
};

export const formatMobileNumber = (mobileNumber: string): string => {
  if (mobileNumber.length > 10) {
    const lastTenDigits = mobileNumber.slice(-10);
    return `+${mobileNumber.slice(0, mobileNumber.length - 10)} ${lastTenDigits}`;
  }
  return mobileNumber;
};
