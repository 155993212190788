import { FC, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import axiosApi from "@/utils/axiosInterceptor";
import { Loader } from "../ui/loader";
import { toast } from "sonner";
import useSWR from "swr";
import { cn } from "@/lib/utils";
import { AxiosError } from "axios";

interface IAddressData {
  id?: number;
  address: string;
  city: string;
  state: string;
  pincode: string;
  country: string;
}

interface IAddress {
  geturl?: string;
  className?: string;
}

const CAddress: FC<IAddress> = ({ geturl, className }) => {
  const url = `${import.meta.env.VITE_SERVER_URL}${geturl}`;
  const [editMode, setEditMode] = useState(false);

  const addressValidationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    pincode: Yup.string()
      .required("Pincode is required")
      .matches(/^\d{6}$/, "Pincode must be a 6-digit number"),
    country: Yup.string().required("Country is required"),
  });

  const getAddress = async () => {
    try {
      const response = await axiosApi.get(url);
      return response.data.success;
    } catch (e: any) {
      console.log(e);
      throw new Error(e);
    }
  };

  let swrKey = url;

  if (geturl) {
    swrKey = geturl;
  }

  const { data, error } = useSWR(swrKey, geturl ? getAddress : null);

  const initialData: IAddressData = {
    pincode: data ? data.pincode : "",
    city: data ? data.city : "",
    state: data ? data.state : "",
    country: data ? data.country : "",
    address: data ? data.address : "",
  };
  const handleSubmitData = async (values, method, setSubmitting) => {
    try {
      setSubmitting(true);
      let response;
      if (method === "POST") {
        response = await axiosApi.post(`${url}add/`, values);
      } else if (method === "PUT") {
        response = await axiosApi.put(`${url}update/`, values);
      }
      setSubmitting(false);
      setEditMode(false);
      toast.success(response.data.message);
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <div className={cn("rounded-xl border shadow-lg", className)}>
      <div className="flex h-14 items-center border-b lg:h-[60px]">
        <p className="font-bold capitalize">Address details</p>
      </div>
      <Formik
        enableReinitialize // This allows the form to reinitialize when the initialValues change
        initialValues={initialData}
        validationSchema={addressValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const editedData = Object.keys(values).reduce((acc, key) => {
            if (initialData[key] !== values[key]) {
              acc[key] = values[key];
            }
            return acc;
          }, {});
          handleSubmitData(
            data ? editedData : values,
            data ? "PUT" : "POST",
            setSubmitting,
          );
        }}
      >
        {({ handleChange, handleBlur, isSubmitting }) => (
          <Form className="mt-2">
            <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
              {Object.entries(initialData).map(([key, value]) => (
                <div key={key} className="my-2 text-black dark:text-white">
                  <p className="pb-1 text-sm capitalize">
                    {" "}
                    {key.replace(/_/g, " ")}
                  </p>
                  <Input
                    defaultValue={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!editMode}
                    className={`${geturl && !editMode ? "text-base" : ""}`}
                    type="text"
                    id={key}
                    name={key}
                  />
                  <ErrorMessage
                    name={key}
                    render={(msg) => (
                      <div className="text-xs text-red-500">{msg}</div>
                    )}
                  />
                </div>
              ))}
            </div>
            {/* Submit Button */}
            <div className="mt-2 flex justify-end">
              {editMode && (
                <Button variant={"outline"} type="submit">
                  {!isSubmitting && "Submit"}
                  {isSubmitting && <Loader size={20} colors=""></Loader>}
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {geturl && !editMode && (
        <Button variant={"outline"} onClick={() => setEditMode(true)}>
          Edit
        </Button>
      )}
    </div>
  );
};

export default CAddress;
