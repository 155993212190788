import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Loader } from "@/components/ui/loader";
import { cn } from "@/lib/utils";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { FC, useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import axiosApi from "@/utils/axiosInterceptor";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

interface FollowUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  editvalue: any;
  onSubmit: (values) => void;
}
const FollowUpModal: FC<FollowUpModalProps> = ({
  isOpen,
  onClose,
  editvalue,
  onSubmit,
}) => {
  const { projectId } = useParams();

  const initialData = {
    message: editvalue.message ? editvalue.message : "",
    trigger_type: editvalue.trigger_type ? editvalue.trigger_type : "",
    file: editvalue.file ? editvalue.file : "",
    trigger_value: editvalue.trigger_value ? editvalue.trigger_value : "",
  };

  const FollowUpValidationSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
    trigger_type: Yup.string().required("Trigger type is required"),
    file: Yup.mixed().required("File is required"),
    trigger_value: Yup.string().required("Trigger value is required"),
  });
  const [editMode, setEditMode] = useState(false);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("value-->", values);
    try {
      setSubmitting(true);
      axiosApi.put(
        `${import.meta.env.VITE_SERVER_URL}/api/v1/builder/project/${projectId}/followup/${editvalue.id}/update/`,
        { ...values },
      );
      setSubmitting(false);
      toast.success("updated sucessfully");
      onClose();
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-7xl">
          <div className={cn("my-8 ")}>
            <p className="mb-3 font-bold underline underline-offset-2 dark:text-white">
              Follow-Up Details
            </p>
            <Formik
              enableReinitialize
              initialValues={initialData}
              validationSchema={FollowUpValidationSchema}
              onSubmit={(values, { setSubmitting }) => {
                const editedData = Object.keys(values).reduce((acc, key) => {
                  if (initialData[key] !== values[key]) {
                    acc[key] = values[key];
                  }
                  return acc;
                }, {});
                handleSubmit(editedData, setSubmitting);
              }}
            >
              {({ handleChange, handleBlur, isSubmitting, values }) => (
                <Form className="">
                  <div className="grid grid-cols-1 ">
                    <div className="my-2 text-black dark:text-white">
                      <p className="pb-1 text-sm capitalize">Message</p>
                      {editMode ? (
                        <Textarea
                          defaultValue={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!editMode}
                          className={`${!editMode ? "border-none p-0 text-base" : ""} `}
                          id={"message"}
                          name={"message"}
                        />
                      ) : (
                        <div className="flex items-center">
                          <div className="whitespace-pre-line rounded-lg border bg-green-100 p-3 dark:text-black">
                            {values.message}
                          </div>
                        </div>
                      )}
                      <ErrorMessage
                        name={"message"}
                        render={(msg) => (
                          <div className="text-xs text-red-500">{msg}</div>
                        )}
                      />
                    </div>
                    <div className="my-2 text-black dark:text-white">
                      <p className="pb-1 text-sm capitalize">File</p>

                      {/* <Input
                        defaultValue={values.file}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!editMode}
                        className={`${!editMode ? "border-none p-0 text-base" : ""} `}
                        type="file"
                        id="file"
                        name="file"
                      /> */}
                      <ErrorMessage
                        name="file"
                        render={(msg) => (
                          <div className="text-xs text-red-500">{msg}</div>
                        )}
                      />
                    </div>
                    <div className="my-2 text-black dark:text-white">
                      <p className="pb-1 text-sm capitalize">Trigger Type</p>

                      <Input
                        defaultValue={values.trigger_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!editMode}
                        className={`${!editMode ? "border-none p-0 text-base" : ""} `}
                        placeholder="days"
                        id="trigger_type"
                        name="trigger_type"
                      />
                      <ErrorMessage
                        name="trigger_type"
                        render={(msg) => (
                          <div className="text-xs text-red-500">{msg}</div>
                        )}
                      />
                    </div>
                    <div className="my-2 text-black dark:text-white">
                      <p className="pb-1 text-sm capitalize">Trigger Value</p>
                      <Input
                        defaultValue={values.trigger_value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!editMode}
                        className={`${!editMode ? "border-none p-0 text-base" : ""} `}
                        placeholder="HH:MM"
                        type="number"
                        id="trigger_value"
                        name="trigger_value"
                      />
                      <ErrorMessage
                        name="trigger_value"
                        render={(msg) => (
                          <div className="text-xs text-red-500">{msg}</div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="mt-2 flex justify-end">
                    {editMode && (
                      <Button type="submit">
                        {!isSubmitting && "Submit"}
                        {isSubmitting && <Loader size={20} colors="" />}
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
            {!editMode && (
              <Button onClick={() => setEditMode(true)}>Edit</Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FollowUpModal;
