import { Dialog, DialogContent } from "@/components/ui/dialog";
import TemplateForm from "./template-form";
import { useParams } from "react-router-dom";

const TemplateModal = ({ isOpen, onClose, templateId }) => {
  const { projectId } = useParams();
  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-7xl">
        <TemplateForm
          className="border-none shadow-none"
          geturl={
            templateId == 0
              ? undefined
              : `/api/v1/builder/project/${projectId}/template/${templateId}/`
          }
          callback={() => {
            onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TemplateModal;
