// themeSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  currentTheme: string | null;
}

const initialState: ThemeState = {
  currentTheme: sessionStorage.getItem("theme")
    ? sessionStorage.getItem("theme")
    : "light",
};
const handleThemeChange = (theme) => {
  const root = window.document.documentElement;
  root.classList.remove("light", "dark");

  if (theme === "system") {
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";

    root.classList.add(systemTheme);
    return;
  }

  root.classList.add(theme);
};
const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      sessionStorage.setItem("theme", action.payload);
      state.currentTheme = action.payload;
      handleThemeChange(action.payload);
    },
  },
});

export const { setTheme } = themeSlice.actions;
export const selectTheme = (state: { theme: ThemeState }) =>
  state.theme.currentTheme;

export default themeSlice.reducer;
