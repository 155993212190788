// CFilterModal.tsx
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import axiosApi from "@/utils/axiosInterceptor";
import { Button } from "../ui/button";
import { DatePickerWithRange } from "../date-picker/date-range-picker";
import CustomDropdown from "../CDropdown";
import { ExportEndPoints } from "@/api/endpoints";
import { toast } from "sonner";

interface CFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  endPoint?: string;
  submit?: () => void;
}

interface IFilterObject {
  lead_date: { start_date: string; end_date: string };
  visit_date: { start_date: string; end_date: string };
  status: Array<string>;
  intrested_in: string;
}
const checkboxStyle =
  "h-5 w-5 mr-2 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700";

const CFilterModal: React.FC<CFilterModalProps> = ({
  isOpen,
  onClose,
  endPoint,
  submit,
}) => {
  const [datesValue, setDatesValue] = useState("");

  const [filterObject, setFilterObject] = useState<any>();

  const [status, setStatus] = useState([]);

  const [interested, setInterested] = useState<any>("3_BHK");

  const handleFilterReport = async () => {
    try {
      const response = await axiosApi.post(
        `${import.meta.env.VITE_SERVER_URL}${ExportEndPoints.EXPORTLEAD}`,
        filterObject,
        {
          responseType: "blob", // Set the response type to 'blob'
        },
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      // Set the file name (you can adjust this to the desired file name)
      const fileName = "exported_file.xlsx";
      downloadLink.download = fileName;

      // Append the download link to the document
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the download link from the document
      document.body.removeChild(downloadLink);

      // Additional handling or callback if needed
    } catch (err) {
      toast.error("Error downloading");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="text-lg font-bold">
        <DialogHeader className="m-2 ">
          <DialogTitle className="text-center text-lg font-bold">
            Reports
          </DialogTitle>
        </DialogHeader>
        <p className="text-sm font-extralight italic">
          please select value first than checkbox
        </p>
        <div className="flex items-center ">
          <input
            type="checkbox"
            className={checkboxStyle}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterObject({ ...filterObject, lead_date: datesValue });
              } else {
                delete filterObject.lead_date;
              }
            }}
          ></input>
          Lead date :
          <DatePickerWithRange
            selectedDate={setDatesValue}
            className="ml-2"
          ></DatePickerWithRange>
        </div>
        <div className="flex items-center ">
          <input
            type="checkbox"
            className={checkboxStyle}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterObject({ ...filterObject, visit_date: datesValue });
              } else {
                delete filterObject.visit_date;
              }
            }}
          ></input>
          Visit date :
          <DatePickerWithRange
            selectedDate={setDatesValue}
            className="ml-2"
          ></DatePickerWithRange>
        </div>
        <div className="flex items-center ">
          <input
            type="checkbox"
            className={checkboxStyle}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterObject({ ...filterObject, status: status });
              } else {
                delete filterObject.status;
              }
            }}
          ></input>

          <CustomDropdown
            title="status"
            options={["OPEN", "CLOSE"]}
            getDataCallback={(dat) => setStatus(dat)}
            getValue=""
            displayKey=""
            editOptions={[]}
          ></CustomDropdown>
        </div>
        <div className="flex items-center ">
          <input
            type="checkbox"
            className={checkboxStyle}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterObject({ ...filterObject, intrested_in: interested });
              } else {
                delete filterObject.intrested_in;
              }
            }}
          ></input>
          Interested:
          <select onChange={(e) => setInterested(e.target.value)}>
            <option value="">Select</option>
            <option value="3_BHK">3 BHK</option>
            <option value="4_BHK">4 BHK</option>
          </select>
        </div>
        <Button onClick={handleFilterReport}>Submit</Button>
      </DialogContent>
    </Dialog>
  );
};

export default CFilterModal;
