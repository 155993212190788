import { Button } from "@/components/ui/button";
import ProjectCard from "./project-card";
import ProjectModal from "./project-modal";
import { useState } from "react";
import useSWR from "swr";
import axiosApi from "@/utils/axiosInterceptor";
import { ProjectEndPoints } from "@/api/endpoints";
import { Link } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";

const Projects = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleGetProject = async (url) => {
    try {
      const response = await axiosApi.get(url);
      return response.data;
    } catch (e) {
      console.error("Error fetching projects:", e);
      throw e; // Rethrow the error to let SWR handle it
    }
  };
  const { data, error, isLoading } = useSWR(
    `${import.meta.env.VITE_SERVER_URL}${ProjectEndPoints.GET_PROJECT}`,
    handleGetProject,
  );

  return (
    <>
      <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
        <p className="font-bold capitalize">Projects</p>
        <Button className=" ml-auto" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? "Close Project" : "Create Project"}
        </Button>
      </div>
      <div className="p-4">
        <div className="grid grid-flow-row ">
          {isOpen && (
            <ProjectModal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} />
          )}
          <div className="grid gap-y-5 rounded-lg  sm:grid sm:grid-cols-2 sm:gap-2 md:grid-cols-3 md:gap-3 lg:grid-cols-4 lg:gap-4">
            {isLoading ? (
              <div className="flex flex-col space-y-3">
                <Skeleton className="h-[125px] w-[250px] rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-[250px]" />
                  <Skeleton className="h-4 w-[200px]" />
                </div>
              </div>
            ) : error ? (
              // Display an error message if data fetching fails
              <div className="error">
                Error fetching projects: {error.message}
              </div>
            ) : data && data.success && data.success.length ? (
              // Render project cards only if data exists and is not null
              data.success.map((project) => (
                <Link
                  to={`/pages/projects/${project.id}/${project.status}/${project.followup_status}/${project.total_followup}`}
                  key={project.id}
                >
                  <ProjectCard {...project} />
                </Link>
              ))
            ) : (
              // Handle the case when data is null or undefined
              <div className="flex text-3xl">No projects available.</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
