import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import Layout from "./screenComponents/layout";
import ErrorPage from "../error-page";
import { setUser } from "@/redux/services/userSlice";
import axiosApi from "@/utils/axiosInterceptor";
import { AuthEndPoints } from "@/api/endpoints";
import {
  Dashboard,
  Billing,
  ComingSoon,
  FollowUp,
  Staff,
  Reports,
  Reminder,
  Projects,
  ProjectDetailView,
  CompanyForm,
  MayTAPI,
} from "./sections";

import ProfileView from "./sections/profile/profile-view";


const ScreenRoutes = () => {
  const dispatch = useDispatch();

  // Fetch user data using useSWR
  const { data: userData, error: userError, isLoading } = useSWR(
    `${import.meta.env.VITE_SERVER_URL + AuthEndPoints.LOGIN}`,
    async (url) => {
      const response = await axiosApi.get(url);
      return response.data.success;
    }
  );

  useEffect(() => {
    if (userData && !isLoading) {
      dispatch(setUser(userData));
    }
  }, [userData, isLoading, dispatch]);

  if (userError) {
    console.error("Error fetching user data:", userError); // Log the error for debugging purposes
    return <ErrorPage />; // Render an error page or handle the error in your code
  }

  return (
    <Routes>

      <Route path="/" element={<Layout />}>
        {/* Index route */}
        <Route index element={<Dashboard />} />

        {/* Static routes */}
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="comingsoon" element={<ComingSoon />} />
        <Route path="reminder" element={<Reminder />} />
        <Route path="projects" element={<Projects />} />
        <Route path="followup" element={<FollowUp />} />
        <Route path="billing" element={<Billing />} />
        <Route path="reports" element={<Reports />} />
        <Route path="staff" element={<Staff />} />
        <Route path="profile" element={<ProfileView />} />
        <Route path="whatsappbot" element={<MayTAPI />} />

        {/* Dynamic route */}
        <Route
          path="projects/:projectId/:status/:followupStatus/:followCount"
          element={<ProjectDetailView />}
        />

        {/* 404 page */}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default ScreenRoutes;
