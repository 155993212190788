import { useParams } from "react-router-dom";
import CAddress from "@/components/address-component/address-component";
import ProjectForm from "./project-form";
import TemplateModal from "./template/template-modal";
import { useState } from "react";
import TemplateView from "./template/template-view";
import FollowUpView from "./follow-up/follow-view";
import AddCustomerModal from "./customer/add-user-modal";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Tabs, TabsContent, TabsTrigger, TabsList } from "@/components/ui/tabs";
import { CustomerView } from "..";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { BadgeInfoIcon, PhoneCallIcon } from "lucide-react";
import { toast } from "sonner";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import BlackOutForm from "./blackout/blackout-form";

const ProjectDetailView = () => {
  const { projectId, status } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomerModal, setIsCustomerModal] = useState(false);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  const formatMobileNumber = (mobileNumber: string): string => {
    if (mobileNumber.length > 10) {
      const lastTenDigits = mobileNumber.slice(-10);
      return `+${mobileNumber.slice(0, mobileNumber.length - 10)} ${lastTenDigits}`;
    }
    return mobileNumber;
  };

  return (
    <>
      <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
        <p className="font-bold capitalize">{"Project data"}</p>
      </div>
      <div className="p-4">
        <div className="mb-4">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/pages/dashboard">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/pages/projects">project</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink>{projectId}</BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <Tabs defaultValue="Project" className="my-5">
          <ScrollArea className="sm-w w-full">
            <TabsList>
              <TabsTrigger value="Project">Project</TabsTrigger>
              <TabsTrigger value="Templates">Templates</TabsTrigger>
              <TabsTrigger
                disabled={status == "INACTIVE" ? true : false}
                value="Customer"
              >
                Customer
              </TabsTrigger>
              <TabsTrigger
                disabled={status == "INACTIVE" ? true : false}
                value="Followups"
              >
                Followups
              </TabsTrigger>
              <TabsTrigger
                disabled={status == "INACTIVE" ? true : false}
                value="Blackout"
              >
                Blackout
              </TabsTrigger>
            </TabsList>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
          <TabsContent value="Project">
            <ProjectForm
              geturl={`/api/v1/builder/project/${projectId}`}
              className="border-none shadow-none "
            />
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger>Address</AccordionTrigger>

                <AccordionContent>
                  <CAddress
                    geturl={`/api/v1/builder/project/${projectId}/address/`}
                    className="border-none shadow-none"
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </TabsContent>
          <TabsContent value="Customer">
            <CustomerView
              geturl={`/api/v1/builder/project/${projectId}/customer/`}
            />
          </TabsContent>
          <TabsContent value="Followups">
            <FollowUpView className="border-none shadow-none" />
          </TabsContent>
          <TabsContent value="Blackout">
            <BlackOutForm
            />
          </TabsContent>
          <TabsContent value="Templates">
            <TemplateView projectId={projectId} />
          </TabsContent>
        </Tabs>
        {/* <Alert variant={status == "INACTIVE" ? "destructive" : "default"}>
          <div className="flex">
            <BadgeInfoIcon className="mr-2 h-4 w-4" />
            <AlertTitle>Note</AlertTitle>
          </div>
          <AlertDescription className="ml-1">
            {status == "INACTIVE" ? (
              <ul className="list-inside list-disc">
                <li>This project is not active yet.</li>
                <li>Please contact to our admin / sales team to activate.</li>
              </ul>
            ) : (
              <ul className="list-inside list-disc">
                <li>You can added follow up feature</li>
                <li>Please contact to our admin / sales team to activate.</li>
              </ul>
            )}
            <p
              className="mt-2 flex cursor-pointer items-center"
              onClick={() =>
                copyToClipboard(formatMobileNumber("917440323456"))
              }
            >
              <PhoneCallIcon className="mr-2 h-4 w-4" />{" "}
              {formatMobileNumber("917440323456")} (Nexby)
            </p>
          </AlertDescription>
        </Alert> */}

        {isCustomerModal && (
          <AddCustomerModal
            isOpen={isCustomerModal}
            onClose={() => setIsCustomerModal(!isCustomerModal)}
          />
        )}
      </div>
    </>
  );
};

export default ProjectDetailView;
